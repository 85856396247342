export default {
  en: {
    dialogues: 'Dialogues',
    confirmDeleteDialogue: 'Are you sure you want to delete this dialogue?',
    createNewDialogue: 'Create New Dialogue',
    editDialogue: 'Edit Dialogue',
    dialogueName: 'Name',
    addQuestionAfterResponse: 'Add a question after response',
    deleteResponse: 'Delete Response',
    detailedResponseSheet: 'Detailed Response Sheet',
    deleteQuestion: 'Delete Question',
    addQuestion: 'Add a question',
    addResponse: 'Add Response',
    abnormalValue: 'Abnormal Value',
    normalValue: 'Normal Value',
    warning: 'Warning',
    abnormalValueHint: "An abnormal value can lead to an alert if it respects the activity's alert configurations.",
    endDialogueExecution: 'End Dialogue Execution',
    endDialogueExecutionHint: "This response will stop the dialogue's execution",
    questionResponseTitle: 'Questions / Responses',
    translationTitle: 'Translation',
    expandAll: 'Expand all',
    collapseAll: 'Collapse all',
    translationMissingLegend: 'indicates a missing translation',
    hello: 'Hello',
    helloM: 'Hello Mr.',
    helloW: 'Hello Ms.',
    areYouReady: 'are you ready for your',
    areYouReadyM: 'are you ready for your',
    areYouReadyW: 'are you ready for your',
    start: 'Start',
    restart: 'Restart',
    wasCompleted: 'was successfuly completed',
    your: 'Your',
    thankYou: 'Thank you',
    pleaseRepeat: "I'm having trouble understanding your answer. Could you repeat, please?",
    pleaseAnswerYesOrNo: 'Please answer with yes or no',
    pleaseAnswerRange: 'Please answer with one of the numbers within the range.',
    bePolite: 'Please be polite!',
    answerType: 'Answer Type',
    questionType: 'Question Type',
    'questionType.yesno': 'Yes / No',
    'questionType.range': 'Range 1-10',
    'questionType.custom': 'Custom',
    'questionType.open': 'Open',
    noAnswerOpenQuestion: "Patient's response to the open question",
    value: 'Value',
    icon: 'Icon',
    'icon.excited': 'Very Well',
    'icon.happy': 'Well',
    'icon.neutral': 'Neutral',
    'icon.sad': 'Not Well',
    'icon.sick': 'Sick',
    'icon.angry': 'Angry',
    answer: 'Answer',
  },
  fr: {
    dialogues: 'Dialogues',
    confirmDeleteDialogue: 'Voulez-vous supprimer ce dialogue?',
    createNewDialogue: 'Créer un nouveau dialogue',
    editDialogue: 'Modifier le dialogue',
    dialogueName: 'Nom',
    addQuestionAfterResponse: 'Ajouter une question à la suite de la réponse',
    deleteResponse: 'Supprimer la réponse',
    detailedResponseSheet: 'Réponse feuille détaillée',
    deleteQuestion: 'Supprimer la question',
    addQuestion: 'Ajouter une question',
    addResponse: 'Ajouter une réponse',
    abnormalValue: 'Valeur anormale',
    normalValue: 'Valeur normale',
    warning: 'Avertissement',
    abnormalValueHint:
      "Une valeur anormale peut entraîner une alerte si elle respecte les configurations d'alertes de l'activité",
    endDialogueExecution: "Fin d'exécution du dialogue",
    endDialogueExecutionHint: "Cette réponse va arrêter l'exécution du dialogue",
    questionResponseTitle: 'Questions / Réponses',
    translationTitle: 'Traduction',
    expandAll: 'Tout développer',
    collapseAll: 'Tout réduire',
    translationMissingLegend: 'indique une traduction manquante',
    hello: 'Bonjour',
    helloM: 'Bonjour M.',
    helloW: 'Bonjour Mme',
    areYouReady: 'êtes-vous prêt pour votre',
    areYouReadyM: 'êtes-vous prêt pour votre',
    areYouReadyW: 'êtes-vous prête pour votre',
    start: 'Débuter',
    restart: 'Recommencer',
    wasCompleted: 'a été complété avec succès',
    your: 'Votre',
    thankYou: 'Merci',
    pleaseRepeat: "J'ai de la difficulté à comprendre votre réponse. Pouvez-vous répéter s'il vous plait?",
    pleaseAnswerYesOrNo: "S'il vous plait, répondez par oui ou non.",
    pleaseAnswerRange: "S'il vous plait, répondez par un des nombres dans l'échelle.",
    bePolite: "Soyez poli s'il vous plait!",
    answerType: 'Type de réponse',
    questionType: 'Type de question',
    'questionType.yesno': 'Oui / Non',
    'questionType.range': 'Échelle 1-10',
    'questionType.custom': 'Personnalisée',
    'questionType.open': 'Ouverte',
    noAnswerOpenQuestion: 'Réponse du patient à la question ouverte',
    value: 'Value',
    icon: 'Icône',
    'icon.excited': 'Très bien',
    'icon.happy': 'Bien',
    'icon.neutral': 'Neutre',
    'icon.sad': 'Mal',
    'icon.sick': 'Malade',
    'icon.angry': 'Fâché',
    answer: 'Réponse',
  },
};
