<template>
  <v-container>
    <v-row v-if="!authenticated" justify="center">
      <v-col cols="auto">
        <v-row justify="center">
          <v-col cols="auto">
            <div class="mb-6 mx-auto">
              <img ref="logoImg" class="logo" src="/static/img/logo-gray.png" />
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card :class="[step === 'mfaSetup' ? 'width-mfasetup' : 'width-350']" elevation="4">
              <v-card-title> {{ $t('signIn') }} </v-card-title>
              <v-card-text>
                <v-form v-if="!step" id="login-form" ref="loginForm" lazy-validation @submit.prevent>
                  <v-text-field
                    :value="formData.email"
                    autofocus
                    autocomplete="username"
                    filled
                    :label="$t('email')"
                    class="required-indicator"
                    :rules="[validationRules.required, validationRules.email]"
                    @change="(value) => (formData.email = value)"
                  ></v-text-field>
                  <v-text-field
                    v-model="formData.password"
                    type="password"
                    autocomplete="current-password"
                    filled
                    class="required-indicator"
                    :rules="[validationRules.required]"
                    :label="$t('password')"
                  ></v-text-field>
                </v-form>

                <v-form v-if="step === 'mfa'" id="mfa-form" ref="mfaForm" lazy-validation @submit.prevent>
                  <v-row dense>
                    <v-col cols="12">
                      <div class="text-subtitle-1 font-weight-bold">{{ $t('mfaTitle') }}</div>
                    </v-col>
                    <v-col cols="12">
                      <div class="text-body-2">{{ $t('mfaText') }}</div>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="formData.code"
                        autofocus
                        autocomplete="one-time-code"
                        inputmode="numeric"
                        filled
                        class="required-indicator"
                        :rules="[validationRules.required]"
                        :label="$t('mfaCode')"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-form>

                <v-form
                  v-if="step === 'mfaSetup'"
                  id="mfasetup-form"
                  ref="mfaSetupForm"
                  lazy-validation
                  @submit.prevent
                >
                  <v-row dense>
                    <v-col cols="12">
                      <div class="text-subtitle-1 font-weight-bold">{{ $t('mfaSetupTitle') }}</div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <MFASetupSteps
                        :association-key="mfaAssociationKey"
                        :error="mfaSetupError"
                        @update="(value) => (formData.mfaSetupCode = value)"
                      ></MFASetupSteps>
                    </v-col>
                  </v-row>
                </v-form>

                <v-form
                  v-if="step === 'forgotPassword'"
                  id="forgot-password-form"
                  ref="forgotPasswordForm"
                  lazy-validation
                  @submit.prevent
                >
                  <v-row dense>
                    <v-col cols="12">
                      <div class="text-subtitle-1 font-weight-bold">{{ $t('forgotPasswordTitle') }}</div>
                    </v-col>
                    <v-col cols="12">
                      <div class="text-body-2">{{ $t('forgotPasswordText') }}</div>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        :value="formData.email"
                        autofocus
                        autocomplete="username"
                        filled
                        class="required-indicator"
                        :rules="[validationRules.required, validationRules.email]"
                        :label="$t('email')"
                        @change="(value) => (formData.email = value)"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-form>

                <v-form
                  v-if="step === 'confirmForgotPassword'"
                  id="confirm-forgot-password-form"
                  ref="confirmForgotPasswordForm"
                  lazy-validation
                  @submit.prevent
                >
                  <v-row dense>
                    <v-col cols="12">
                      <div class="text-body-2">{{ $t('confirmForgotPasswordText') }}</div>
                    </v-col>
                    <v-col cols="12">
                      <!-- the username field is there and hidden in order to help password managers -->
                      <v-text-field
                        v-show="false"
                        :value="formData.email"
                        autocomplete="username"
                        filled
                        :label="$t('email')"
                        @change="(value) => (formData.email = value)"
                      ></v-text-field>
                      <v-text-field
                        v-model="formData.code"
                        data-1pignore
                        autocomplete="off"
                        filled
                        class="required-indicator"
                        :rules="[validationRules.required]"
                        :label="$t('resetPasswordCode')"
                      ></v-text-field>
                      <v-text-field
                        id="new-password"
                        v-model="formData.newPassword"
                        type="password"
                        filled
                        autocomplete="new-password"
                        class="required-indicator"
                        :rules="[validationRules.required, validatePasswordStructure]"
                        :label="$t('newPassword')"
                      ></v-text-field>
                      <v-text-field
                        id="confirm-password"
                        v-model="formData.newPasswordConfirm"
                        type="password"
                        filled
                        autocomplete="new-password"
                        class="required-indicator"
                        :rules="[validationRules.required, validateNewPassword]"
                        :label="$t('newPasswordConfirm')"
                      ></v-text-field>
                      <div class="wrap-newline">{{ $t('passwordRequirements') }}</div>
                    </v-col>
                  </v-row>
                </v-form>

                <v-form
                  v-if="step === 'changePassword'"
                  id="change-password-form"
                  ref="changePasswordForm"
                  lazy-validation
                  @submit.prevent
                >
                  <v-row dense>
                    <v-col cols="12">
                      <div class="text-subtitle-1 font-weight-bold">{{ $t('changePasswordTitle') }}</div>
                    </v-col>
                    <v-col cols="12">
                      <div class="text-body-2">{{ $t('changePasswordText') }}</div>
                    </v-col>
                    <v-col cols="12">
                      <!-- the username field is there and hidden in order to help password managers -->
                      <v-text-field
                        v-show="false"
                        :value="formData.email"
                        autocomplete="username"
                        filled
                        :label="$t('email')"
                        @change="(value) => (formData.email = value)"
                      ></v-text-field>
                      <v-text-field
                        v-model="formData.newPassword"
                        autofocus
                        type="password"
                        autocomplete="new-password"
                        filled
                        class="required-indicator"
                        :rules="[validationRules.required, validatePasswordStructure]"
                        :label="$t('newPassword')"
                      ></v-text-field>
                      <v-text-field
                        v-model="formData.newPasswordConfirm"
                        filled
                        type="password"
                        autocomplete="new-password"
                        class="required-indicator"
                        :rules="[validationRules.required, validateNewPassword]"
                        :label="$t('newPasswordConfirm')"
                      ></v-text-field>
                      <div class="wrap-newline">{{ $t('passwordRequirements') }}</div>
                    </v-col>
                  </v-row>
                </v-form>
                <v-row v-if="error">
                  <v-col>
                    <div class="red--text">{{ error }}</div>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-btn v-if="step === ''" text small @click="gotoStep('forgotPassword')">{{
                  $t('forgotPassword')
                }}</v-btn>

                <v-spacer></v-spacer>
                <v-btn
                  v-if="step === ''"
                  :loading="loading"
                  :disabled="loading"
                  color="primary"
                  form="login-form"
                  type="submit"
                  @click="signIn"
                >
                  {{ $t('login') }}
                </v-btn>
                <v-btn
                  v-if="step === 'forgotPassword'"
                  form="forgot-password-form"
                  color="primary"
                  type="submit"
                  @click="forgotPassword"
                >
                  {{ $t('reset') }}
                </v-btn>
                <v-btn
                  v-if="step === 'confirmForgotPassword'"
                  color="primary"
                  type="submit"
                  form="confirm-forgot-password-form"
                  @click="confirmForgotPassword"
                >
                  {{ $t('changePassword') }}
                </v-btn>
                <v-btn
                  v-if="step === 'changePassword'"
                  form="change-password-form"
                  color="primary"
                  type="submit"
                  @click="confirmChangePassword"
                >
                  {{ $t('changePassword') }}
                </v-btn>
                <v-btn v-if="step === 'mfaSetup'" color="primary" form="mfasetup-form" type="submit" @click="setupMfa">
                  {{ $t('activate') }}
                </v-btn>
                <v-btn v-if="step === 'mfa'" color="primary" form="mfa-form" type="submit" @click="mfaSignIn">
                  {{ $t('login') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        <v-row
          ><v-col cols="auto">
            <v-select
              v-model="selectedLanguage"
              dense
              flat
              solo
              background-color="transparent"
              label="Langue"
              class="login-language"
              :items="languages"
              hide-details
              @change="changeLanguage(selectedLanguage)"
            ></v-select> </v-col
        ></v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import translation from '@/translationMixin';
import accessibility from '@/accessibilityMixin';
import projectUrlMixin from '@/projectUrlMixin';
import validationRulesMixin from '@/validationRulesMixin';
import auth, { AuthResult } from '@/auth/auth';
import authListener, { ListenerEvents } from '@/auth/authListener';
import MFASetupSteps from '../User/MFASetupSteps.vue';

export default {
  name: 'Login',
  components: { MFASetupSteps },
  mixins: [accessibility, translation, projectUrlMixin, validationRulesMixin],
  props: {
    step: {
      type: [String],
      required: false,
      default: '',
    },
  },
  data() {
    return {
      error: null,
      loading: false,
      formData: {
        email: '',
        password: '',
        newPassword: '',
        newPasswordConfirm: '',
        code: '',
        mfaSetupCode: '',
      },

      languages: [
        { value: 'fr', text: 'Français' },
        { value: 'en', text: 'English' },
      ],
      selectedLanguage: 'fr',
      authenticated: false,
      mfaAssociationKey: '',
      mfaSetupError: null,
    };
  },

  watch: {
    step: {
      immediate: true,
      async handler() {
        this.error = null;
        if (this.step === '') {
          this.code = '';
          return;
        }
        let allowedSteps = ['mfa', 'forgotPassword', 'confirmForgotPassword', 'changePassword', 'mfaSetup'];
        if (!allowedSteps.includes(this.step) || this.$route.query.state !== this.state) {
          this.$router.replace({ name: 'Login' });
          return;
        }

        if (this.step === 'mfaSetup') {
          this.mfaAssociationKey = await auth.setupTOTP();
        }
      },
    },
    selectedLanguage() {
      switch (this.step) {
        case 'mfa':
          this.$refs.mfaForm.resetValidation();
          break;
        case 'forgotPassword':
          this.$refs.forgotPasswordForm.resetValidation();
          break;

        case 'confirmForgotPassword':
          this.$refs.confirmForgotPasswordForm.resetValidation();
          break;

        case 'changePassword':
          this.$refs.changePasswordForm.resetValidation();
          break;
        default:
          this.$refs.loginForm.resetValidation();
          break;
      }
    },
    'formData.email': function () {
      if (this.formData?.email) {
        this.formData.email = this.formData.email.toLowerCase();
      }
    },
    'formData.mfaSetupCode': function () {
      this.mfaSetupError = null;
    },
  },
  created: function () {
    this.init();
    this.state = Math.floor(Math.random() * 10000).toString();
    authListener.on(ListenerEvents.signOut, this.updateAuthenticated);
  },
  beforeDestroy() {
    authListener.off(ListenerEvents.signOut, this.updateAuthenticated);
    const root = document.querySelector(':root');
    root.style.setProperty('--recaptcha-badge-visibility', 'hidden');
  },
  mounted() {
    this.updateAuthenticated();

    const root = document.querySelector(':root');
    root.style.setProperty('--recaptcha-badge-visibility', 'visible');
  },
  methods: {
    init() {
      this.selectedLanguage = this.getLanguage();
    },
    async signIn() {
      this.error = null;
      if (!this.$refs.loginForm.validate()) {
        return;
      }
      try {
        this.loading = true;
        let signInResult = await auth.signIn(this.formData.email, this.formData.password);
        this.handleAuthResult(signInResult);
      } catch (error) {
        this.error = error;
      } finally {
        this.loading = false;
      }
    },
    async mfaSignIn() {
      this.error = null;

      if (!this.$refs.mfaForm.validate()) {
        return;
      }

      let signInResult = await auth.confirmSignIn(this.formData.code);
      this.handleAuthResult(signInResult);
    },
    async forgotPassword() {
      if (this.$refs.forgotPasswordForm.validate()) {
        let result = await auth.forgotPassword(this.formData.email);
        if (result) {
          this.gotoStep('confirmForgotPassword');
        } else {
          this.handleAuthResult(AuthResult.Error);
        }
      }
    },
    async confirmForgotPassword() {
      if (this.$refs.confirmForgotPasswordForm.validate()) {
        let result = await auth.forgotPasswordSubmit(
          this.formData.email,
          this.formData.code,
          this.formData.newPassword
        );
        if (result === AuthResult.Successs) {
          this.gotoStep(undefined);
        } else {
          this.handleAuthResult(result);
        }
      }
    },
    async confirmChangePassword() {
      if (this.$refs.changePasswordForm.validate()) {
        let result = await auth.completeNewPassword(this.formData.newPassword);
        if (result !== AuthResult.Successs) {
          this.handleAuthResult(result);
        }
      }
    },
    handleAuthResult(signInResult) {
      this.error = null;
      switch (signInResult) {
        case AuthResult.MFARequired:
          this.gotoStep('mfa');
          break;
        case AuthResult.NewPasswordRequired:
          this.gotoStep('changePassword');
          break;
        case AuthResult.MFASetup:
          this.gotoStep('mfaSetup');
          break;
        case AuthResult.InvalidCredentials:
          this.error = this.$t('invalidCredentials');
          break;
        case AuthResult.InvalidCode:
          this.error = this.$t('invalidCode');
          break;
        case AuthResult.ExpiredCode:
          this.error = this.$t('expiredCode');
          break;
        case AuthResult.InvalidPasswordStructure:
          this.error = this.$t('invalidPasswordStructure');
          break;
        case AuthResult.LimitExceeded:
          this.error = this.$t('limitExceeded');
          break;
        case AuthResult.ReCaptchaValidationFailed:
          this.error = this.$t('recaptchaValidationFailed');
          break;
        case AuthResult.TooManyAttempts:
          this.error = this.$t('tooManyAttempts');
          break;
        case AuthResult.Error:
        case AuthResult.UnhandledChallenge:
          this.error = this.$t('unknownLoginError');
          break;
        default:
          break;
      }
    },
    gotoStep(step) {
      let params = { step: step };
      if (this.$route.params.redirect) {
        params.redirect = this.$route.params.redirect;
      }
      this.$router.push({ name: 'Login', params: params, query: { state: this.state } });
    },
    validateNewPassword(value) {
      return this.formData.newPassword === value || this.$t('passwordMismatch');
    },
    validatePasswordStructure(value) {
      const passwordExpression =
        /^(?!\s+)(?!.*\s+$)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])[A-Za-z0-9$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{8,256}$/;
      return passwordExpression.test(value) || this.$t('invalidPasswordStructure');
    },
    updateAuthenticated() {
      this.authenticated = auth.isAuthenticated();
    },
    async setupMfa() {
      if (!this.formData.mfaSetupCode) {
        this.mfaSetupError = this.$t('requiredField');
        return;
      }
      const isVerified = await auth.verifyTOTP(this.formData.mfaSetupCode);
      if (!isVerified) {
        this.mfaSetupError = this.$t('invalidCode');
        return;
      }

      const signedIn = await auth.silentSignIn();
      if (!signedIn) {
        // Should not happen
        this.error = this.$t('unknownLoginError');
        return;
      }

      // It's not mandatory to activate the MFA, but since we're asked to configure the MFA in the login process it must be because the pool require the MFA
      // so we're going to set the user to explicitly use the mfa
      await auth.activateMFA();
    },
  },
};
</script>

<style scoped>
.login-language {
  max-width: 140px;
}

.width-350 {
  width: 350px;
}
.width-mfasetup {
  max-width: 500px;
}

.logo {
  height: 100px;
}
</style>
