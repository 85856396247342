import api from './baseApiService';

export class IotService {
  async getIOTDeviceParametersInformations() {
    return await api.get('iotdeviceparameters');
  }

  async getIOTDeviceTypesByActivityType(activityTypeId) {
    return await api.get(`iotdevicetypesbyactivitytype/${activityTypeId}`);
  }

  async getIots(data) {
    return await api.get('iotdevices/', data);
  }

  async deleteIot(iotDeviceId) {
    return await api.delete(`iotdevice/${iotDeviceId}/delete/`);
  }

  async getIotById(iotDeviceId) {
    return await api.get(`iotdevice/${iotDeviceId}`);
  }

  async createIot(data) {
    return await api.post('iotdevice/', data);
  }

  async updateIot(iotDeviceId, data) {
    return await api.put(`iotdevice/${iotDeviceId}/`, data);
  }

  async configureIot(iotDeviceId, data) {
    return await api.post(`iotdevice/${iotDeviceId}/configure/`, data);
  }

  async getIotDeviceTypes() {
    return await api.get('iotdevicetypes');
  }

  async getIotRoom(iotDeviceId) {
    return await api.get(`iotdeviceroom/${iotDeviceId}`);
  }

  async updateIotRoom(iotDeviceId, data) {
    return await api.put(`iotdeviceroom/${iotDeviceId}/`, data);
  }

  async getIotDeviceRooms() {
    return await api.get(`iotdevicerooms`);
  }

  async getPatientIOTDevices(patientId) {
    return await api.get(`patientiotdevices/${patientId}`);
  }

  async updatePatientIOTDevice(patientId, data) {
    return await api.put(`patientiotdevice/${patientId}/`, data);
  }

  async getExistingPatientAssociationData(iotDeviceId) {
    return await api.get(`iotdevice/${iotDeviceId}/associationdata`);
  }

  async associatePatientToDevice(data) {
    return await api.post(`associatepatientdevice/`, data);
  }

  async removePatientDevice(iotDeviceId, data) {
    return await api.put(`removepatientdevice/${iotDeviceId}/`, data);
  }
}

export default new IotService();
