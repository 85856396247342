<template>
  <v-app class="background-light">
    <v-app-bar v-if="userLogged && $vuetify.breakpoint.xs" app dark color="#4c4c4c" elevate-on-scroll>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <img class="logo--small" src="/static/img/logo-small.png" />

      <v-spacer></v-spacer>

      <CallbackRequestButton />

      <v-btn icon @click="showNotificationModal = !showNotificationModal">
        <v-icon>mdi-bell </v-icon>
        <NotificationCount ref="notificationCount" />
      </v-btn>
    </v-app-bar>

    <v-bottom-sheet v-model="showNotificationModal">
      <NotificationModal
        v-if="showNotificationModal"
        ref="notificationModal"
        :close-dialog="closeDialog"
        @notifs-deleted="showSnackbar"
      />
    </v-bottom-sheet>

    <v-navigation-drawer
      v-if="userLogged && $vuetify.breakpoint.xs"
      v-model="drawer"
      dark
      color="#4c4c4c"
      width="300px"
      app
      disable-resize-watcher
      touchless
    >
      <SideNavigation @show-snackbar="showSnackbar" />
    </v-navigation-drawer>

    <v-navigation-drawer
      v-if="userLogged && !$vuetify.breakpoint.xs"
      class="background-light navigation-drawer-ellipse"
      dark
      app
      width="300px"
      permanent
      stateless
    >
      <div class="ellipse-sidenav__root">
        <SideNavigation class="ellipse-sidenav__left" @show-snackbar="showSnackbar" />
        <div class="background-dark ellipse-sidenav__ellipse"></div>
      </div>
    </v-navigation-drawer>

    <v-main>
      <router-view />
      <v-snackbar v-model="snackbar.show" :timeout="snackbar.timeout" color="success">
        {{ snackbar.text }}
      </v-snackbar>
    </v-main>

    <CallbackRequestButton v-if="userLogged && $vuetify.breakpoint.smAndUp" />

    <IdleTimeout v-if="userLogged"></IdleTimeout>
  </v-app>
</template>

<script>
import SideNavigation from '@/components/SideNavigation.vue';
import NotificationCount from '@/components/Notification/NotificationCount';
import NotificationModal from '@/components/Notification/NotificationModal';
import CallbackRequestButton from '@/components/CallbackRequest/CallbackRequestButton';
import translationMixin from '@/translationMixin';
import listener, { ListenerEvents } from '@/auth/authListener';
import userService from '@/services/userService';
import { defineAbility } from '@/auth/ability';
import * as permissions from '@/auth/permissions';
import applicationService from './services/applicationService';
import IdleTimeout from './components/IdleTimeout.vue';

export default {
  name: 'App',
  components: {
    SideNavigation,
    NotificationCount,
    NotificationModal,
    CallbackRequestButton,
    IdleTimeout,
  },
  mixins: [translationMixin],

  data() {
    return {
      drawer: false,
      userLogged: false,
      username: '',
      isSuperUser: false,
      error: null,

      showNotificationModal: false,
      snackbar: {
        show: false,
        text: this.$t('alertNotificationsDeleted'),
        timeout: 2000,
      },
    };
  },

  watch: {
    '$vuetify.breakpoint.xs'(newValue) {
      if (!newValue) {
        this.showNotificationModal = false;
      }
    },
  },
  created: function () {
    this.init();
  },
  destroyed() {
    listener.off(ListenerEvents.signIn, this.signedIn);
    listener.off(ListenerEvents.signOut, this.signedOut);
  },

  methods: {
    init: function () {
      listener.on(ListenerEvents.signIn, this.signedIn);
      listener.on(ListenerEvents.signOut, this.signedOut);
    },
    closeDialog: function () {
      this.showNotificationModal = false;
    },
    async signedIn() {
      if (!this.userLogged) {
        await this.loadUserPermissions();
        this.userLogged = true;

        await this.updateUserLanguage();
        await this.loadProximiteSettings();

        let route = this.$route;

        if (route.name === 'Login' && route.params.redirect && route.params.redirect.name !== 'Home') {
          this.$router.push(route.params.redirect);
        } else if (
          (route.name === 'Login' || route.name === 'Home') &&
          this.$can(permissions.actions.view, permissions.subjects.patientMonitoring)
        ) {
          this.$router.push({ name: 'PatientMonitoringGrid' });
        } else if (route.name === 'Login') {
          this.$router.push({ name: 'Home' });
        }
      }
    },
    async loadProximiteSettings() {
      const setting = await applicationService.getProximiteConfig();
      this.$store.commit('setProximiteIsAvailable', setting.proximiteIsEnabled);
    },
    signedOut() {
      if (this.userLogged) {
        this.userLogged = false;
        this.$router.push({ name: 'Login' });
      }
    },
    async updateUserLanguage() {
      try {
        let userPreference = await userService.getUserPreferences();

        if (this.getLanguage() !== userPreference.userLanguage) {
          this.changeLanguage(userPreference.userLanguage);
        }
      } catch {
        // do nothing
      }
    },
    async loadUserPermissions() {
      try {
        let permissions = await userService.getCurrentUserPermissions();
        this.$ability.update(defineAbility(permissions).rules);
      } catch {
        // do nothing
      }
    },
    showSnackbar() {
      this.snackbar.show = true;
    },
  },
};
</script>

<style scoped>
.ellipse-sidenav__ellipse {
  width: 100%;
  height: 100%;
  clip-path: ellipse(100% 60% at 0% 50%);
}

.ellipse-sidenav__root {
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: stretch;
}

.ellipse-sidenav__left {
  width: 256px;
}

.navigation-drawer-ellipse :deep(.v-navigation-drawer__border) {
  display: none;
}

.logo--small {
  height: 40px;
}
</style>
