<template>
  <div>
    <ErrorModal :error="error" @close-error-modal="error = null" />
    <ModificationWarnModal :has-modifications="hasModifications" />
    <v-snackbar v-model="snackbar.show" :timeout="5000" color="success">
      {{ snackbar.text }}
    </v-snackbar>
    <ConfirmationModal
      ref="editWarnModal"
      :question="$t('user.confirmRemoveAccess')"
      :show="showEditWarnMessage"
      :title="$t('save')"
      @cancel="showEditWarnMessage = false"
      @ok="createOrUpdateAction"
    />

    <ConfirmationModal
      :question="$t('user.confirmResetMfa')"
      :show="showResetMfaConfirmation"
      :title="$t('reset')"
      @cancel="showResetMfaConfirmation = false"
      @ok="resetMfa"
    />

    <ConfirmationModal
      :question="$t('user.confirmDisableMfa')"
      :show="showDisableMfaConfirmation"
      :title="$t('disable')"
      @cancel="showDisableMfaConfirmation = false"
      @ok="disableMfa"
    />

    <v-dialog v-if="show" ref="dialogRef" value="show" scrollable persistent :max-width="dialogMaxWidth">
      <v-card>
        <ModalTitle :title="title" />

        <WaitModal :show="showWaitModal" />

        <UserError :error-array="requiredErrorMessages" @clearMsgs="clearErrorMessages" />

        <v-card-text>
          <v-form ref="userModalForm" lazy-validation>
            <v-row>
              <v-col cols="12" :md="userId != null ? 8 : 12">
                <v-text-field
                  ref="lastName"
                  v-model="formData.lastName"
                  filled
                  :label="$t('lastName')"
                  class="required-indicator"
                  :rules="[validationRules.required]"
                />

                <v-text-field
                  ref="firstName"
                  v-model="formData.firstName"
                  filled
                  :label="$t('firstName')"
                  class="required-indicator"
                  :rules="[validationRules.required]"
                />

                <v-autocomplete
                  ref="accessGroups"
                  v-model="formData.accessGroups"
                  :items="accessGroupNames"
                  deletable-chips
                  small-chips
                  item-text="name"
                  item-value="id"
                  multiple
                  filled
                  :label="$t('user.accessGroups')"
                />

                <v-select
                  ref="facilities"
                  v-model="formData.facilities"
                  :items="facilities"
                  deletable-chips
                  small-chips
                  :item-text="facilityName"
                  item-value="id"
                  multiple
                  filled
                  :label="$t('user.facilities')"
                >
                  <template #prepend-item>
                    <v-list-item ripple @mousedown.prevent @click="toggle">
                      <v-list-item-action>
                        <v-icon color="primary">
                          {{ icon }}
                        </v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>{{ $t('selectAll') }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                </v-select>

                <v-text-field
                  ref="email"
                  :value="formData.email"
                  filled
                  :label="$t('user.email')"
                  class="required-indicator"
                  :rules="[validationRules.required, validationRules.email]"
                  validate-on-blur
                  :disabled="!!userId"
                  @change="(value) => (formData.email = value)"
                />
                <v-col cols="auto">
                  <v-checkbox
                    ref="checkActive"
                    v-model="formData.active"
                    :label="$t('active')"
                    :disabled="isCurrentUser"
                  />
                </v-col>
              </v-col>

              <v-col v-if="userId != null" cols="12" md="4">
                <detail-field
                  :id="getIdByName('createdAt')"
                  ref="createdAt"
                  v-model="formData.createdAt"
                  :label="$t('user.createdAt')"
                />

                <detail-field
                  :id="getIdByName('lastLogin')"
                  ref="lastLogin"
                  v-model="formData.lastLogin"
                  :label="$t('user.lastLogin')"
                />
              </v-col>
            </v-row>

            <RequiredFieldsLegend />
          </v-form>
        </v-card-text>

        <v-card-actions class="justify-end">
          <div
            v-if="isResendTempPasswordButtonVisible || isResetMfaVisible || isDisableMfaVisible"
            class="d-flex align-center flex-wrap"
          >
            <v-btn
              v-if="isResendTempPasswordButtonVisible"
              ref="resendUserTempPassword"
              :disabled="showWaitModal"
              small
              class="mr-3"
              color="primary"
              @click="resendUserTempPassword"
            >
              {{ $t('user.resendTempPassword') }}
            </v-btn>

            <v-btn
              v-if="isResetMfaVisible"
              ref="resetMfa"
              :disabled="showWaitModal"
              small
              class="mr-3"
              @click="showResetMfaConfirmation = true"
            >
              {{ $t('user.resetMfa') }}
            </v-btn>
            <v-btn
              v-if="isDisableMfaVisible"
              ref="disableMfa"
              :disabled="showWaitModal"
              small
              class="mr-3"
              @click="showDisableMfaConfirmation = true"
            >
              {{ $t('user.disableMfa') }}
            </v-btn>
          </div>

          <v-spacer></v-spacer>

          <v-btn ref="cancel" text :disabled="isProcessing" @click="closeDialog()">
            {{ $t('cancel') }}
          </v-btn>
          <SaveButton
            :is-loading="isLoading"
            :is-processing="isProcessing"
            :show-wait-modal="showWaitModal"
            :handle-click="isCurrentUser ? checkAndDisplayWarning : createOrUpdateAction"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import accessibility from '@/accessibilityMixin';
import permissionMixin from '@/auth/permissionMixin';
import SaveButton from '@/components/SaveButton.vue';
import accessGroupService from '@/services/accessGroupService';
import userService from '@/services/userService';
import translation from '@/translationMixin';
import validationRulesMixin from '@/validationRulesMixin';
import { UserFormData } from './UserFormData';

export default {
  name: 'UserModal',

  components: { SaveButton },
  mixins: [translation, accessibility, validationRulesMixin, permissionMixin],

  props: {
    userId: {
      type: Number,
      required: false,
      default: null,
    },
    isCurrentUser: {
      type: Boolean,
      required: true,
    },
    show: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      error: null,
      showWaitModal: false,
      isProcessing: false,
      isLoading: false,
      title: '',
      formData: new UserFormData(),
      multipleOrganizations: false,
      originalFormData: {},
      accessGroupNames: [],
      facilities: [],
      mfaEnabled: false,
      forceChangePassword: false,
      showEditWarnMessage: false,
      showResetMfaConfirmation: false,
      showDisableMfaConfirmation: false,
      cognitoPoolMfaEnforcement: '',
      snackbar: {
        show: false,
        text: '',
      },
    };
  },
  computed: {
    dialogMaxWidth: function () {
      return this.userId !== null ? '1200px' : '800px';
    },

    isResendTempPasswordButtonVisible: function () {
      return (
        this.$can(this.permissions.actions.add, this.permissions.subjects.user) &&
        this.formData.email &&
        !!this.userId &&
        this.forceChangePassword
      );
    },
    isResetMfaVisible() {
      return (
        !!this.userId &&
        this.cognitoPoolMfaEnforcement === 'ON' &&
        this.mfaEnabled &&
        !this.forceChangePassword &&
        !this.isCurrentUser
      );
    },
    isDisableMfaVisible() {
      return (
        !!this.userId &&
        this.cognitoPoolMfaEnforcement === 'OPTIONAL' &&
        this.mfaEnabled &&
        !this.forceChangePassword &&
        !this.isCurrentUser
      );
    },
    facilityName: function () {
      return (facility) => {
        return facility.name + (this.multipleOrganizations ? ' (' + facility.organization.name + ')' : '');
      };
    },

    selectedAllFacilities() {
      return this.formData.facilities?.length === this.facilities?.length;
    },
    selectedSomeFacilities() {
      return this.formData.facilities?.length > 0 && !this.selectedAllFacilities;
    },
    icon() {
      if (this.selectedAllFacilities) return 'mdi-checkbox-marked';
      if (this.selectedSomeFacilities) return 'mdi-minus-box';
      return 'mdi-checkbox-blank-outline';
    },
  },
  watch: {
    show: async function () {
      if (this.show) {
        await this.getAccessGroupNames();
        await this.getAllFacilities();

        if (this.userId != null) {
          await this.editClicked();
        } else {
          this.init();
        }
      } else {
        this.formData.reset();
      }
    },
    'formData.email': function () {
      if (this.formData?.email) {
        this.formData.email = this.formData.email.toLowerCase();
      }
    },
  },
  methods: {
    init: function () {
      this.title = this.$t('user.createNewUser');
      this.formData.reset();
      this.originalFormData = JSON.parse(JSON.stringify(this.formData));
    },

    async editClicked() {
      this.title = this.$t('user.editUser');
      this.showWaitModal = true;

      try {
        let user = await userService.getUserById(this.userId);
        this.formData.setData(user);
        this.originalFormData = JSON.parse(JSON.stringify(this.formData));
        this.mfaEnabled = !!user.mfaEnabled;
        this.forceChangePassword = !!user.forceChangePassword;

        this.cognitoPoolMfaEnforcement = user.cognitoPoolMfaEnforcement;
      } catch (error) {
        this.error = error;
      }
      this.showWaitModal = false;
    },

    async createOrUpdateAction() {
      this.showEditWarnMessage = false;

      var isValid = this.$refs.userModalForm.validate();

      if (!isValid || this.isProcessing) return;

      this.showWaitModal = true;
      this.isProcessing = true;

      var data = {
        firstName: this.formData.firstName,
        lastName: this.formData.lastName,
        email: this.formData.email,
        accessGroups: this.formData.accessGroups,
        facilities: this.formData.facilities,
        active: this.formData.active,
      };

      try {
        if (this.userId === null) {
          await userService.createUser(data);
        } else {
          await userService.updateUser(this.userId, data);
        }
        this.closeDialog();
        this.$emit('refresh', true);
      } catch (error) {
        this.error = error;
      }
      this.showWaitModal = false;
      this.isProcessing = false;
    },

    async getAccessGroupNames() {
      this.isLoading = true;

      try {
        this.accessGroupNames = await accessGroupService.getAccessGroupNames();
      } catch (error) {
        this.error = error;
      }

      this.isLoading = false;
    },

    async getAllFacilities() {
      this.isLoading = true;

      try {
        this.facilities = await userService.getAllFacilities();
        this.multipleOrganizations = new Set(this.facilities.map((facility) => facility.organization.id)).size > 1;
      } catch (error) {
        this.error = error;
      }

      this.isLoading = false;
    },

    async resendUserTempPassword() {
      try {
        this.showWaitModal = true;

        await userService.resendEmailTempPassword(this.userId);
        this.snackbar.text = this.$t('user.userTempPasswordResent');
        this.snackbar.show = true;
      } catch (error) {
        this.error = error;
      }

      this.showWaitModal = false;
    },
    async resetMfa() {
      this.showResetMfaConfirmation = false;
      try {
        this.showWaitModal = true;

        await userService.resetMfa(this.userId);
        this.snackbar.text = this.$t('user.mfaResetted');
        this.snackbar.show = true;
        this.forceChangePassword = true;
      } catch (error) {
        this.error = error;
      }

      this.showWaitModal = false;
    },
    async disableMfa() {
      this.showDisableMfaConfirmation = false;
      try {
        this.showWaitModal = true;

        await userService.disableMfa(this.userId);
        this.snackbar.text = this.$t('user.mfaDisabled');
        this.snackbar.show = true;
        this.mfaEnabled = false;
      } catch (error) {
        this.error = error;
      }

      this.showWaitModal = false;
    },

    closeDialog: function () {
      this.$emit('update:show', false);
    },

    hasModifications: function () {
      if (this.show) {
        return JSON.stringify(this.formData) !== JSON.stringify(this.originalFormData);
      }
    },

    toggle() {
      this.$nextTick(() => {
        if (this.formData.facilities.length > 0) {
          this.formData.facilities = [];
        } else {
          this.formData.facilities = this.facilities.map((facility) => facility.id);
        }
      });
    },

    checkAndDisplayWarning: function () {
      const hasAtLeastSameAccessGroups = this.originalFormData?.accessGroups?.every((item) =>
        this.formData.accessGroups.includes(item)
      );

      if (hasAtLeastSameAccessGroups) {
        this.createOrUpdateAction();
      } else {
        this.showEditWarnMessage = true;
      }
    },
  },
};
</script>

<style scoped>
.dialog-tabs.v-tabs-items {
  overflow: visible;
}
</style>
