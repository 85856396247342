<template>
  <span>
    <ModificationWarnModal :has-modifications="hasModifications" />
    <v-dialog v-if="show" value="show" scrollable persistent max-width="550">
      <v-card>
        <ModalTitle :title="$t('userProfile')" />
        <v-tabs v-if="formData.healthworkerProfile" ref="userProfileTabs" v-model="selectedUserProfileTab" class="px-6">
          <v-tab> {{ $t('userProfile') }} </v-tab>
          <v-tab> {{ $t('user.healthworker') }} </v-tab>
        </v-tabs>
        <v-card-text>
          <v-tabs-items v-model="selectedUserProfileTab" class="patient-tabs">
            <v-tab-item v-if="!isLoading" eager class="pt-4">
              <v-form ref="userProfileForm">
                <v-text-field
                  :id="getIdByName('lastName')"
                  ref="lastName"
                  v-model="formData.userProfile.lastName"
                  :label="$t('lastName')"
                  filled
                  :class="'required-indicator'"
                  :rules="[validationRules.required]"
                  :hint="
                    formData.userProfile.lastName !== originalFormData.userProfile.lastName
                      ? $t('user.updateUserLastName')
                      : ''
                  "
                  persistent-hint
                />

                <v-text-field
                  :id="getIdByName('firstName')"
                  ref="firstName"
                  v-model="formData.userProfile.firstName"
                  :label="$t('firstName')"
                  filled
                  :class="'required-indicator'"
                  :rules="[validationRules.required]"
                  :hint="
                    formData.userProfile.firstName !== originalFormData.userProfile.firstName
                      ? $t('user.updateUserFirstName')
                      : ''
                  "
                  persistent-hint
                />

                <detail-field ref="email" :value="formData.userProfile.email" :label="$t('email')" />

                <detail-field ref="accessGroups" :value="accessGroupsString" :label="$t('user.accessGroups')" />

                <detail-field ref="facilities" :value="facilitiesString" :label="$t('user.facilities')" />
              </v-form>
            </v-tab-item>
            <v-tab-item v-if="formData.healthworkerProfile && !isLoading" eager>
              <v-form ref="healthworkerForm">
                <detail-field
                  ref="healthworkerLastName"
                  :value="formData.healthworkerProfile.lastName"
                  :label="$t('lastName')"
                />

                <detail-field
                  ref="healthworkerFirstName"
                  :value="formData.healthworkerProfile.firstName"
                  :label="$t('firstName')"
                />

                <detail-field
                  :id="getIdByName('healthworkerType')"
                  ref="healthworkerType"
                  :value="formData.healthworkerProfile.type"
                  :label="$t('healthWorkerType')"
                />

                <v-text-field
                  :id="getIdByName('healthworkerPhone')"
                  ref="healthworkerPhone"
                  v-model="formData.healthworkerProfile.phone"
                  :label="$t('phone')"
                  filled
                  class="required-indicator mt-4"
                  :rules="[validationRules.required]"
                />
              </v-form>
            </v-tab-item>
          </v-tabs-items>
          <RequiredFieldsLegend />
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn ref="cancel" :disabled="isProcessing" @click="cancel()">
            {{ $t('cancel') }}
          </v-btn>
          <SaveButton
            v-if="!formData.system"
            :is-loading="isLoading"
            :is-processing="isProcessing"
            :show-wait-modal="showWaitModal"
            :handle-click="saveProfile"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import translation from '@/translationMixin';
import accessibility from '@/accessibilityMixin';
import validationRulesMixin from '@/validationRulesMixin';
import userService from '@/services/userService';
import SaveButton from '@/components/SaveButton.vue';

export default {
  name: 'UserProfile',
  components: { SaveButton },
  mixins: [translation, accessibility, validationRulesMixin],
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      formData: {
        userProfile: {
          firstName: '',
          lastName: '',
          email: '',
          accessGroups: [],
          facilities: [],
        },
        healthworkerProfile: null,
      },
      originalFormData: {
        userProfile: {
          firstName: '',
          lastName: '',
          email: '',
          accessGroups: [],
          facilities: [],
        },
        healthworkerProfile: null,
      },
      error: null,
      isProcessing: false,
      isLoading: false,
      showWaitModal: false,
      selectedUserProfileTab: 0,
      accessGroupsString: '',
      facilitiesString: '',
    };
  },

  watch: {
    show: async function () {
      if (this.show) {
        this.selectedUserProfileTab = 0;
        await this.getUserProfile();
      }
    },
  },

  methods: {
    async getUserProfile() {
      this.isLoading = true;
      try {
        let result = await userService.getUserProfile();
        this.formData = result;
        this.accessGroupsString = this.formData.userProfile.accessGroups.join('\n');
        this.facilitiesString = this.formData.userProfile.facilities.join('\n');
        this.originalFormData = JSON.parse(JSON.stringify(this.formData));
      } catch (error) {
        this.error = error;
      }

      this.isLoading = false;
    },

    cancel: function () {
      this.$emit('update:show', false);
    },

    async saveProfile() {
      let phone = null;
      let isValid = this.$refs.userProfileForm.validate();

      if (!isValid || this.isProcessing) {
        this.selectedUserProfileTab = 0;
        return;
      }

      if (this.formData.healthworkerProfile) {
        isValid = this.$refs.healthworkerForm.validate();
        phone = this.formData.healthworkerProfile.phone;

        if (!isValid || this.isProcessing) {
          this.selectedUserProfileTab = 1;
          return;
        }
      }

      this.showWaitModal = true;
      this.isProcessing = true;

      if (this.hasModifications()) {
        try {
          let data = {
            firstName: this.formData.userProfile.firstName,
            lastName: this.formData.userProfile.lastName,
            healthworkerPhone: phone,
          };
          await userService.updateUserProfile(data);
        } catch (error) {
          this.error = error;
        }
      }

      this.isProcessing = false;
      this.$emit('update:show', false);
      this.showWaitModal = false;
    },

    hasModifications: function () {
      if (this.show) {
        return JSON.stringify(this.formData) !== JSON.stringify(this.originalFormData);
      }
    },
  },
};
</script>
