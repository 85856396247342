<template>
  <div>
    <v-row>
      <v-col cols="1">
        <span>
          <v-avatar color="primary" size="24"><span class="white--text font-weight-bold">1</span></v-avatar>
        </span>
      </v-col>
      <v-col cols="11">
        <span>{{ $t('mfa.downloadApp') }}</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="1"></v-col>
      <v-col cols="11" class="pa-0">
        <v-list flat dense class="pa-0">
          <v-list-item>
            <v-list-item-content>
              <span
                >{{ $t('mfa.androidDevices') }}:
                <a :href="googleAuthenticatorLinks.android" target="_blank" rel="noopener noreferrer">{{
                  $t('mfa.googleAuthenticatorAndroid')
                }}</a></span
              >
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <span
                >{{ $t('mfa.iOSDevices') }}:
                <a :href="googleAuthenticatorLinks.iOS" target="_blank" rel="noopener noreferrer">{{
                  $t('mfa.googleAuthenticatorIOS')
                }}</a></span
              >
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="1">
        <span class="pr-2">
          <v-avatar color="primary" size="24"><span class="white--text font-weight-bold">2</span></v-avatar>
        </span>
      </v-col>
      <v-col cols="11">
        <span class="wrap-newline">{{ $t('mfa.scanQRCode') }}</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="1"></v-col>
      <v-col cols="auto" class="py-0">
        <span class="py-4">
          <v-progress-circular
            v-if="!associationKey"
            indeterminate
            color="primary"
            class="card-width"
          ></v-progress-circular>
          <qrcode v-else ref="qrcode" :value-input="associationKey" />
        </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="1">
        <span class="pr-2">
          <v-avatar color="primary" size="24"><span class="white--text font-weight-bold">3</span></v-avatar>
        </span>
      </v-col>
      <v-col cols="11">
        <span>{{ $t('mfa.enterCode') }}</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="1"></v-col>
      <v-col cols="4" class="py-0">
        <v-text-field
          v-model="verificationCode"
          dense
          outlined
          :label="$t('mfaCode')"
          type="number"
          hide-spin-buttons
          :error-messages="error"
        ></v-text-field>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import translation from '@/translationMixin';
import Qrcode from '@/components/Qrcode.vue';

const DEFAULT_GOOGLE_AUTHENTICATOR_LINK_ANDROID =
  'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&gl=CA';
const DEFAULT_GOOGLE_AUTHENTICATOR_LINK_IOS = 'https://apps.apple.com/ca/app/google-authenticator/id388497605';

export default {
  name: 'MFASetupSteps',
  components: { Qrcode },
  mixins: [translation],
  props: {
    associationKey: {
      type: String,
      default: '',
    },
    error: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      googleAuthenticatorLinks: {
        android: null,
        iOS: null,
      },
      isAssociationKeyLoading: false,
      verificationCode: null,
      isVerificationError: false,
    };
  },
  watch: {
    verificationCode() {
      this.$emit('update', this.verificationCode);
    },
  },
  mounted() {
    const userLanguage = this.getLanguage();
    this.googleAuthenticatorLinks.android = `${DEFAULT_GOOGLE_AUTHENTICATOR_LINK_ANDROID}&hl=${userLanguage}`;
    this.googleAuthenticatorLinks.iOS = `${DEFAULT_GOOGLE_AUTHENTICATOR_LINK_IOS}?l=${userLanguage}`;
  },
  methods: {},
};
</script>

<style scoped>
.word-break-normal {
  word-break: normal;
}
</style>
