class RecaptchaHandler {
  constructor() {
    this._key = '';
  }

  configure(key) {
    this._key = key;
    if (this._key === '') {
      return '';
    }

    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/enterprise.js?render=${key}`;
    script.async = true;
    script.nonce = 'EEAD79A6F622816D5160D0787C86A804';
    document.head.appendChild(script);
  }

  async getToken(action) {
    if (this._key === '') {
      return '';
    }

    const token = await global.grecaptcha.enterprise.execute(this._key, {
      action: action,
    });

    return token;
  }
}

export default new RecaptchaHandler();
