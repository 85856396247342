import api from './baseApiService';

export class IotRealtimeService {
  async getRealtimeActivityTypes() {
    return await api.get(`realtimeactivitytypes`);
  }

  async getAllIotsRealtime(data) {
    return await api.get('iotrealtime/', data);
  }

  async getPatientIotRealTime(patientId) {
    return await api.get(`iotrealtime/${patientId}`);
  }

  async getPatientEcgObservations(patientId) {
    return await api.get(`ecgrealtime/${patientId}`);
  }

  async getPatientEcg(patientId, data) {
    return await api.get(`patientecg/${patientId}`, data);
  }

  async getPatientVitalSigns(patientId, data) {
    return await api.get(`patientvitalsigns/${patientId}`, data);
  }
}

export default new IotRealtimeService();
