<template>
  <v-dialog v-if="show && !isMfaActive" value="show" eager scrollable persistent max-width="600">
    <v-card>
      <v-card-title class="text-h5 word-break-normal"> {{ $t('mfaTitle') }}</v-card-title>

      <v-card-subtitle class="py-2">
        {{ $t('mfa.followSteps') }}
      </v-card-subtitle>
      <div style="margin-bottom: 20px"></div>

      <v-card-text>
        <MFASetupSteps
          :association-key="associationKey"
          :error="isVerificationError ? $t('invalidCode') : ''"
          @update="(value) => (verificationCode = value)"
        ></MFASetupSteps>
      </v-card-text>

      <v-card-actions class="justify-end">
        <v-btn text :disabled="isProcessing" @click="cancel">{{ $t('cancel') }}</v-btn>
        <v-btn text color="primary" :disabled="isProcessing" @click="activateMFA">{{ $t('mfa.activate') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <ConfirmationModal
    v-else-if="show && isMfaActive"
    :question="$t('mfa.confirmDeactivate')"
    :title="$t('mfa.deactivate')"
    :show="true"
    :is-disabled="isProcessing"
    @cancel="closeDialog"
    @ok="deactivateMFA"
  />
</template>

<script>
import translation from '@/translationMixin';
import auth from '@/auth/auth';
import MFASetupSteps from './MFASetupSteps.vue';

export default {
  components: { MFASetupSteps },
  mixins: [translation],
  props: {
    isMfaActive: {
      type: Boolean,
      required: true,
    },
    show: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isProcessing: false,
      associationKey: null,
      verificationCode: null,
      isVerificationError: false,
    };
  },
  watch: {
    async show(newValue) {
      if (newValue && !this.isMfaActive) {
        this.associationKey = await auth.setupTOTP();
      } else if (!newValue) {
        this.reset();
      }
    },
  },
  methods: {
    reset() {
      this.associationKey = null;
      this.verificationCode = null;
      this.isVerificationError = false;
    },
    async activateMFA() {
      if (this.isProcessing) return;
      this.isProcessing = true;
      const isVerified = await auth.verifyTOTP(this.verificationCode);

      try {
        if (!isVerified) {
          this.isVerificationError = true;
        } else {
          const isMfaActive = await auth.activateMFA();
          this.$emit('update:isMfaActive', isMfaActive);
          this.closeDialog();
        }
      } finally {
        this.isProcessing = false;
      }
    },
    async deactivateMFA() {
      this.isProcessing = true;
      const isMfaDeactivated = await auth.deactivateMFA();
      const isMfaActive = !isMfaDeactivated;
      this.$emit('update:isMfaActive', isMfaActive);
      this.closeDialog();
    },
    cancel() {
      this.closeDialog();
    },
    closeDialog: function () {
      this.isProcessing = false;
      this.$emit('update:show', false);
    },
  },
};
</script>

<style scoped>
.word-break-normal {
  word-break: normal;
}
</style>
