<template>
  <span>
    <ErrorModal :error="error" @close-error-modal="error = null" />
    <ModificationWarnModal :has-modifications="hasModifications" />

    <v-dialog
      v-if="show"
      ref="dialogRef"
      value="show"
      scrollable
      persistent
      :max-width="cohortId != null ? '1000px' : '800px'"
    >
      <v-card class="eb-white-bg">
        <ModalTitle :title="title" />

        <WaitModal :show="showWaitModal" />

        <UserError :error-array="requiredErrorMessages" @clearMsgs="clearErrorMessages" />

        <v-card-text>
          <v-form ref="cohortModalForm">
            <v-row>
              <v-col cols="12" :md="cohortId != null ? 6 : 12">
                <v-text-field
                  :id="getIdByName('cohortName')"
                  ref="cohortName"
                  v-model="formData.cohortName"
                  :label="$t('cohortName')"
                  filled
                  class="required-indicator"
                  :rules="[validationRules.required]"
                />

                <v-text-field
                  :id="getIdByName('description')"
                  ref="description"
                  v-model="formData.description"
                  :label="$t('description')"
                  filled
                  class="required-indicator"
                  :rules="[validationRules.required]"
                />

                <!-- <v-checkbox ref="autoUpdate" v-model="formData.autoUpdate" :label="$t('autoUpdate')"> </v-checkbox> -->

                <!-- <v-checkbox ref="checkActive" v-model="formData.active" :label="$t('active')"> </v-checkbox> -->
                <RequiredFieldsLegend />
              </v-col>

              <v-col v-if="cohortId !== null" :cols="$vuetify.breakpoint.smAndDown ? 12 : 'auto'">
                <detail-field
                  v-if="cohortId !== null"
                  :id="getIdByName('createdDate')"
                  ref="createdDate"
                  v-model="formData.createdDate"
                  :label="$t('createdDate')"
                />

                <detail-field
                  v-if="cohortId !== null"
                  :id="getIdByName('createdBy')"
                  ref="createdBy"
                  v-model="formData.createdBy"
                  :label="$t('createdBy')"
                />

                <detail-field
                  v-if="cohortId !== null"
                  :id="getIdByName('modifiedDate')"
                  ref="modifiedDate"
                  v-model="formData.modifiedDate"
                  :label="$t('modifiedDate')"
                />

                <detail-field
                  v-if="cohortId !== null"
                  :id="getIdByName('modifiedBy')"
                  ref="modifiedBy"
                  v-model="formData.modifiedBy"
                  :label="$t('modifiedBy')"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn ref="cancel" text :disabled="isProcessing" @click="closeDialog()">
            {{ $t('cancel') }}
          </v-btn>
          <SaveButton
            :is-loading="isLoading"
            :is-processing="isProcessing"
            :show-wait-modal="showWaitModal"
            :handle-click="createOrUpdateAction"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import translation from '@/translationMixin';
import accessibility from '@/accessibilityMixin';
import validationRulesMixin from '@/validationRulesMixin';
import CohortService from '@/services/cohortsService';
import SaveButton from '@/components/SaveButton.vue';

export default {
  name: 'CohortModal',

  components: { SaveButton },
  mixins: [translation, accessibility, validationRulesMixin],

  props: {
    cohortId: {
      type: Number,
      required: false,
      default: null,
    },

    show: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      error: null,
      title: '',
      showWaitModal: false,
      isProcessing: false,
      isLoading: false,
      formData: {
        cohortName: '',
        description: '',
        autoUpdate: false,
        active: false,
        createdDate: null,
        createdBy: null,
        modifiedDate: null,
        modifiedBy: null,
      },
      originalFormData: {},
    };
  },
  created: function () {
    this.init();
  },
  methods: {
    async init() {
      this.title = this.$t('createCohort');
      this.formData = {
        cohortName: '',
        description: '',
        autoUpdate: false,
        active: false,
        createdDate: null,
        createdBy: null,
        modifiedDate: null,
        modifiedBy: null,
      };
      this.originalFormData = JSON.parse(JSON.stringify(this.formData));

      if (this.cohortId !== null) {
        await this.editClicked();
      }
    },

    async editClicked() {
      this.title = this.$t('editCohort');
      this.showWaitModal = true;

      try {
        let cohort = await CohortService.getCohort(this.cohortId);
        this.formData.cohortName = cohort.name;
        this.formData.description = cohort.description;
        this.formData.autoUpdate = cohort.autoUpdate;
        this.formData.active = cohort.active;
        this.formData.createdDate = cohort.createdAt;
        this.formData.createdBy = cohort.createdBy.username;
        this.formData.modifiedDate = cohort.updatedAt;
        this.formData.modifiedBy = cohort.updatedBy.username;
        this.originalFormData = JSON.parse(JSON.stringify(this.formData));
      } catch (error) {
        this.error = error;
      }
      this.showWaitModal = false;
    },

    async createOrUpdateAction() {
      let isValid = this.$refs.cohortModalForm.validate();

      if (!isValid || this.isProcessing) return;

      let data = {
        name: this.formData.cohortName,
        description: this.formData.description,
        autoUpdate: this.formData.autoUpdate,
        active: this.formData.active,
      };

      this.showWaitModal = true;
      this.isProcessing = true;

      if (this.cohortId !== null) {
        data.id = this.cohortId;
        try {
          await CohortService.updateCohort(this.cohortId, data);
          this.closeDialog();
          this.$emit('refresh');
        } catch (error) {
          this.error = error;
        }
      } else {
        try {
          await CohortService.createCohort(data);
          this.closeDialog();
          this.$emit('refresh');
        } catch (error) {
          this.error = error;
        }
      }
      this.showWaitModal = false;
      this.isProcessing = false;
    },

    closeDialog: function () {
      this.$emit('update:show', false);
    },

    hasModifications: function () {
      if (this.show) {
        return JSON.stringify(this.formData) !== JSON.stringify(this.originalFormData);
      }
    },
  },
};
</script>

<style scoped></style>
