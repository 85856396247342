<template>
  <span>
    <ErrorModal :error="error" @close-error-modal="error = null" />
    <ModificationWarnModal :has-modifications="hasModifications" />

    <v-dialog v-if="show" ref="dialogRef" value="show" scrollable persistent max-width="1200px">
      <v-card color="white">
        <ModalTitle :title="title" />
        <WaitModal :show="showWaitModal" />
        <UserError :error-array="requiredErrorMessages" @clearMsgs="clearErrorMessages" />

        <v-card-text>
          <v-row>
            <v-col cols="12" :md="dialogueId != null ? 6 : 12">
              <v-form ref="dialogueModalForm">
                <v-text-field
                  :id="getIdByName('dialogueName')"
                  ref="dialogueName"
                  v-model="formData.name"
                  :label="$t('dialogueName')"
                  filled
                  class="required-indicator"
                  :rules="[validationRules.required]"
                />

                <v-text-field
                  :id="getIdByName('description')"
                  ref="description"
                  v-model="formData.description"
                  :label="$t('description')"
                  filled
                  class="required-indicator"
                  :rules="[validationRules.required]"
                />
              </v-form>
              <v-row>
                <!-- <v-col cols="auto">
                  <v-checkbox ref="checkActive" v-model="formData.active" :label="$t('active')" />
                </v-col> -->
                <v-col cols="auto">
                  <v-checkbox
                    ref="checkNormative"
                    v-model="formData.normative"
                    :label="$t('normative')"
                    :disabled="!$can(permissions.actions.manage, permissions.subjects.normative)"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col v-if="dialogueId != null" cols="12" md="6">
              <detail-field
                :id="getIdByName('createdDate')"
                ref="createdDate"
                v-model="formData.createdDate"
                :label="$t('createdDate')"
              />
              <detail-field
                :id="getIdByName('createdBy')"
                ref="createdBy"
                v-model="formData.createdBy"
                :label="$t('createdBy')"
              />
              <detail-field
                :id="getIdByName('modifiedDate')"
                ref="modifiedDate"
                v-model="formData.modifiedDate"
                :label="$t('modifiedDate')"
              />
              <detail-field
                :id="getIdByName('modifiedBy')"
                ref="modifiedBy"
                v-model="formData.modifiedBy"
                :label="$t('modifiedBy')"
              />
            </v-col>
          </v-row>
          <v-row v-if="formData.parameters.length" class="pt-0">
            <v-col class="pt-0">
              <v-tabs v-model="selectedTab" background-color="transparent">
                <v-tab>{{ $t('questionResponseTitle') }}</v-tab>
                <v-tab>
                  <div>{{ $t('translationTitle') }}</div>
                  <v-icon v-if="translationsMissing" class="ml-2" small>mdi-alert</v-icon>
                </v-tab>
              </v-tabs>
            </v-col>
          </v-row>
          <v-row v-if="formData.parameters.length">
            <v-col>
              <v-tabs-items v-model="selectedTab" class="tabs">
                <v-tab-item>
                  <v-row>
                    <v-col>
                      <v-row dense>
                        <v-col>
                          <v-select
                            v-model="selectedLanguage"
                            :items="languages"
                            item-text="name"
                            item-value="code"
                            :label="$t('language')"
                            outlined
                            dense
                            hide-details
                          >
                          </v-select>
                        </v-col>
                        <v-col cols="auto">
                          <v-tooltip bottom>
                            <template #activator="{ on }">
                              <v-btn icon @click="toggleExpand()" v-on="on">
                                <v-icon>{{
                                  openList.length === 0 ? 'mdi-expand-all-outline' : 'mdi-collapse-all-outline'
                                }}</v-icon>
                              </v-btn>
                            </template>
                            {{ openList.length === 0 ? $t('expandAll') : $t('collapseAll') }}
                          </v-tooltip>
                        </v-col>
                      </v-row>
                      <v-treeview
                        ref="treeview"
                        :open="openList"
                        class="pt-2"
                        hoverable
                        dense
                        :items="formData.parameters"
                        activatable
                        return-object
                        :active.sync="activeItems"
                      >
                        <template #label="{ item }">
                          {{ getParameterDisplayText(item) }}
                        </template>
                        <template #prepend="{ item, active }">
                          <v-icon :color="active ? 'primary' : undefined">
                            {{
                              item.type === DialogueParameterTypes.QUESTION
                                ? 'mdi-message-question'
                                : 'mdi-message-reply-text-outline'
                            }}
                          </v-icon>
                        </template>
                      </v-treeview>
                    </v-col>
                    <v-col v-if="!!activeItem" cols="auto">
                      <v-divider vertical></v-divider>
                    </v-col>
                    <v-col v-if="!!activeItem">
                      <v-form ref="questionsResponsesForm">
                        <template v-if="activeItem.type === DialogueParameterTypes.QUESTION">
                          <v-textarea
                            v-model="
                              activeItem.languages[
                                activeItem.languages.findIndex((l) => l.language === selectedLanguage)
                              ].value
                            "
                            rows="2"
                            auto-grow
                            filled
                            label="Question"
                            class="required-indicator"
                            :rules="[validationRules.required]"
                          ></v-textarea>
                          <v-select
                            v-model="activeItem.questionType"
                            :items="questionTypes"
                            :label="$t('questionType')"
                            outlined
                            dense
                            @change="resetResponses"
                          >
                            <template #selection="{ item }">
                              <div>
                                {{ $t(`questionType.${item}`) }}
                              </div>
                            </template>
                            <template #item="{ item }">
                              <div>
                                {{ $t(`questionType.${item}`) }}
                              </div>
                            </template>
                          </v-select>
                          <v-btn ref="deleteQuestion" class="mt-4 mr-4" @click="deleteQuestion">{{
                            $t('deleteQuestion')
                          }}</v-btn>
                          <v-btn
                            v-if="
                              activeItem &&
                              activeItem.questionType === QuestionTypes.CUSTOM &&
                              activeItem.children.length < MaxCustomResponses
                            "
                            ref="addResponse"
                            class="mt-4"
                            @click="addResponse(activeItem)"
                            >{{ $t('addResponse') }}</v-btn
                          >
                        </template>
                        <template v-if="activeItem.type === DialogueParameterTypes.RESPONSE">
                          <v-textarea
                            v-if="activeItem.parent.questionType === QuestionTypes.CUSTOM"
                            v-model="
                              activeItem.languages[
                                activeItem.languages.findIndex((l) => l.language === selectedLanguage)
                              ].value
                            "
                            rows="1"
                            auto-grow
                            filled
                            :label="$t('answer')"
                            class="required-indicator"
                            :rules="[validationRules.required]"
                          ></v-textarea>
                          <v-textarea
                            v-if="activeItem.parent.questionType !== QuestionTypes.OPEN"
                            v-model="activeItem.detailResponse"
                            rows="2"
                            auto-grow
                            filled
                            :label="$t('detailedResponseSheet')"
                          ></v-textarea>
                          <v-row v-if="activeItem.parent.questionType !== QuestionTypes.OPEN" dense>
                            <v-col cols="auto" :sm="7">
                              <v-select
                                v-model="activeItem.alertLevel"
                                filled
                                :items="alertLevels"
                                :label="$t('answerType')"
                                persistent-hint
                                :hint="$t('abnormalValueHint')"
                              ></v-select>
                            </v-col>
                            <v-col cols="auto" :sm="5">
                              <v-select
                                v-model="activeItem.icon"
                                filled
                                :items="responseIcons"
                                :label="$t('icon')"
                                clearable
                                class="d-flex justify-center"
                              >
                                <template #selection="{ item }">
                                  <div class="d-flex justify-center full-width">
                                    <v-row align="center">
                                      <v-col cols="3">
                                        <v-icon>{{ getMdiEmoticon(item) }}</v-icon>
                                      </v-col>
                                      <v-col cols="9">
                                        <span>{{ $t(`icon.${item}`) }}</span>
                                      </v-col>
                                    </v-row>
                                  </div>
                                </template>
                                <template #item="{ item }">
                                  <div class="d-flex justify-center full-width">
                                    <v-row align="center">
                                      <v-col cols="3">
                                        <v-icon>{{ getMdiEmoticon(item) }}</v-icon>
                                      </v-col>
                                      <v-col cols="9">
                                        <span>{{ $t(`icon.${item}`) }}</span>
                                      </v-col>
                                    </v-row>
                                  </div>
                                </template>
                              </v-select>
                            </v-col>
                          </v-row>

                          <v-checkbox
                            v-model="activeItem.endDialogueExecution"
                            :label="$t('endDialogueExecution')"
                            persistent-hint
                            :hint="$t('endDialogueExecutionHint')"
                          />
                          <v-btn ref="addQuestionAfter" class="mt-4 mr-4" @click="addQuestion(activeItem)">{{
                            $t('addQuestionAfterResponse')
                          }}</v-btn>
                          <v-btn
                            v-if="
                              activeItem.parent.questionType === QuestionTypes.CUSTOM &&
                              activeItem.parent.children.length > MinResponses
                            "
                            ref="deleteResponse"
                            class="mt-4"
                            @click="deleteResponse()"
                            >{{ $t('deleteResponse') }}</v-btn
                          >
                        </template>
                      </v-form>
                    </v-col>
                  </v-row>
                </v-tab-item>
                <v-tab-item>
                  <v-row v-for="(item, index) in flatItems" :key="index">
                    <v-col v-for="language in languages" :key="language.code">
                      <v-textarea
                        v-model="item.languages[item.languages.findIndex((l) => l.language === language.code)].value"
                        :background-color="
                          !item.languages[item.languages.findIndex((l) => l.language === language.code)].value
                            ? 'rgba(251, 140, 0, 0.3)'
                            : undefined
                        "
                        rows="2"
                        auto-grow
                        filled
                        :label="
                          item.type === DialogueParameterTypes.QUESTION
                            ? `Question - ${language.name}`
                            : `${$t('answer')} - ${language.name}`
                        "
                      ></v-textarea>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col>
                      <div class="d-flex align-baseline">
                        <div class="translation-legend-icon"></div>
                        <div class="text-caption ml-2">{{ $t('translationMissingLegend') }}</div>
                      </div>
                    </v-col>
                  </v-row>
                </v-tab-item>
              </v-tabs-items>
            </v-col>
          </v-row>
          <v-row v-if="selectedTab === 0">
            <v-col>
              <v-btn ref="addQuestion" color="primary" @click="addQuestion(null)">{{ $t('addQuestion') }}</v-btn>
            </v-col>
          </v-row>
          <RequiredFieldsLegend />
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn ref="cancel" text :disabled="isProcessing" @click="closeDialog()">
            {{ $t('cancel') }}
          </v-btn>
          <SaveButton
            :is-loading="isLoading"
            :is-processing="isProcessing"
            :show-wait-modal="showWaitModal"
            :handle-click="createOrUpdateAction"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import translation from '@/translationMixin';
import accessibility from '@/accessibilityMixin';
import validationRulesMixin from '@/validationRulesMixin';
import permissionMixin from '@/auth/permissionMixin';
import emoticonMixin from '@/components/Dialogue/emoticonMixin';
import DialogueService from '@/services/dialogueService';
import CommonService from '@/services/commonService';
import SaveButton from '@/components/SaveButton.vue';
import {
  DialogueLanguages,
  DialogueParameterTypes,
  EmoticonCodes,
  MaxCustomResponses,
  MinResponses,
  QuestionTypes,
  YesNoValues,
} from '@/components/Dialogue/constants';

export default {
  name: 'DialogueModal',

  components: { SaveButton },
  mixins: [translation, accessibility, validationRulesMixin, permissionMixin, emoticonMixin],

  props: {
    dialogueId: {
      type: Number,
      required: false,
      default: null,
    },
    show: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      error: null,
      showWaitModal: false,
      isProcessing: false,
      isLoading: false,
      title: '',
      formData: {
        name: '',
        description: '',
        normative: false,
        active: false,
        createdDate: null,
        createdBy: null,
        modifiedDate: null,
        modifiedBy: null,
        parameters: [],
      },
      originalFormData: {},
      selectedLanguage: DialogueLanguages.FRENCH,
      selectedTab: 0,
      languages: [],
      questionTypes: [],
      activeItems: [],
      displayYes: { fr: 'Oui', en: 'Yes' },
      displayNo: { fr: 'Non', en: 'No' },
      openList: [],
      alertLevels: [
        { text: this.$t('normalValue'), value: 'normal' },
        { text: this.$t('warning'), value: 'warning' },
        { text: this.$t('abnormalValue'), value: 'abnormal' },
      ],
      responseIcons: [],
      DialogueParameterTypes,
      MaxCustomResponses,
      MinResponses,
      QuestionTypes,
    };
  },
  computed: {
    activeItem() {
      return this.activeItems.length ? this.activeItems[0] : null;
    },
    flatItems() {
      let result = [];
      let processChildren = (items) => {
        items.forEach((item) => {
          if (item.type === DialogueParameterTypes.QUESTION || item.parent?.questionType === QuestionTypes.CUSTOM) {
            result.push(item);
          }
          if (item.children?.length) {
            processChildren(item.children);
          }
        });
      };

      processChildren(this.formData.parameters);
      return result;
    },
    translationsMissing() {
      return this.flatItems.some((item) =>
        this.languages.some(
          (language) => !item.languages[item.languages.findIndex((l) => l.language === language.code)].value
        )
      );
    },
  },
  watch: {
    show: async function () {
      if (this.show) {
        if (this.dialogueId != null) {
          await this.editClicked();
        } else {
          this.init();
        }
        await this.getLanguagesList();
        this.questionTypes = this.getQuestionTypesList();
        this.responseIcons = this.getResponseIconsList();
      }
    },
  },
  created: function () {
    this.init();
  },
  methods: {
    init: function () {
      this.title = this.$t('createNewDialogue');
      this.formData = {
        name: '',
        description: '',
        normative: false,
        active: false,
        createdDate: null,
        createdBy: null,
        modifiedDate: null,
        modifiedBy: null,
        parameters: [],
      };
      this.originalFormData = JSON.parse(JSON.stringify(this.formData));
      this.selectedTab = 0;
      this.activeItems = [];
      this.openList = [];
      this.nextid = 1;
    },
    async getLanguagesList() {
      try {
        let languages = await CommonService.getLanguages();
        this.languages = languages;
      } catch (error) {
        this.error = error;
      }
    },
    getQuestionTypesList() {
      return [QuestionTypes.YESNO, QuestionTypes.RANGE, QuestionTypes.CUSTOM, QuestionTypes.OPEN];
    },
    getResponseIconsList() {
      return [
        EmoticonCodes.EXCITED,
        EmoticonCodes.HAPPY,
        EmoticonCodes.NEUTRAL,
        EmoticonCodes.SAD,
        EmoticonCodes.SICK,
        EmoticonCodes.ANGRY,
      ];
    },
    getParameterDisplayText(parameter) {
      switch (parameter.type) {
        case DialogueParameterTypes.QUESTION:
          return parameter.languages[parameter.languages.findIndex((l) => l.language === this.selectedLanguage)].value;
        case DialogueParameterTypes.RESPONSE:
          switch (parameter.parent.questionType) {
            case QuestionTypes.YESNO:
              switch (parameter.value) {
                case YesNoValues.YES:
                  return this.displayYes[this.selectedLanguage];
                case YesNoValues.NO:
                  return this.displayNo[this.selectedLanguage];
                default:
                  console.error('invalidParameterValue', parameter.value);
                  return;
              }
            case QuestionTypes.RANGE:
              return parameter.value;
            case QuestionTypes.CUSTOM:
              return parameter.languages.find((l) => l.language === this.selectedLanguage).value;
            case QuestionTypes.OPEN:
              return this.$t('noAnswerOpenQuestion');
            default:
              console.error('invalidQuestionType', parameter.parent.questionType);
              return;
          }
        default:
          console.error('invalidParameterType', parameter.type);
          break;
      }
    },
    async editClicked() {
      this.title = this.$t('editDialogue');
      this.showWaitModal = true;
      this.selectedTab = 0;
      this.activeItems = [];
      try {
        let dialogue = await DialogueService.getDialogue(this.dialogueId);
        this.formData.name = dialogue.name;
        this.formData.description = dialogue.description;
        this.formData.normative = dialogue.normative;
        this.formData.active = dialogue.active;
        this.formData.parameters = dialogue.parameters;
        this.formData.createdDate = dialogue.createdAt;
        this.formData.createdBy = dialogue.createdBy != null ? dialogue.createdBy.username : '';
        this.formData.modifiedDate = dialogue.updatedAt;
        this.formData.modifiedBy = dialogue.updatedBy != null ? dialogue.updatedBy.username : '';
        this.originalFormData = JSON.parse(JSON.stringify(this.formData));
        this.setNextId();
        this.formData.parameters.forEach((param) => this.addParentsToResponseChildren(param));
      } catch (error) {
        this.error = error;
      }
      this.showWaitModal = false;
    },

    async createOrUpdateAction() {
      if (this.isProcessing) {
        return;
      }

      if (!this.formData.parameters || !this.formData.parameters.length) {
        await this.addQuestion();
        this.$refs.questionsResponsesForm.validate();
        return;
      }

      let isDialogueModalFormValid = this.$refs.dialogueModalForm ? this.$refs.dialogueModalForm.validate() : true;
      let isQuestionsResponsesFormValid = this.$refs.questionsResponsesForm
        ? this.$refs.questionsResponsesForm.validate()
        : true;

      var isValidParameters = true;
      for (let obj of this.formData.parameters) {
        if (!this.validateParameters(obj)) {
          isValidParameters = false;
          break;
        }
      }

      if (isDialogueModalFormValid && isQuestionsResponsesFormValid && isValidParameters) {
        this.showWaitModal = true;

        let serializeParameter = (item) => {
          let serialized;

          if (item.type === DialogueParameterTypes.QUESTION) {
            serialized = {
              id: item.id,
              type: item.type,
              questionType: item.questionType,
              languages: item.languages.map((x) => {
                return {
                  language: x.language,
                  value: x.value,
                };
              }),
              children: item.children ? item.children.map(serializeParameter) : null,
            };
          } else {
            serialized = {
              id: item.id,
              type: item.type,
              icon: item.icon ? item.icon : null,
              value: item.value ? item.value : null,
              detailResponse: item.detailResponse,
              endDialogueExecution: item.endDialogueExecution,
              children: item.children ? item.children.map(serializeParameter) : null,
              alertLevel: item.alertLevel,
              languages: item.languages
                ? item.languages.map((x) => {
                    return {
                      language: x.language,
                      value: x.value,
                    };
                  })
                : null,
            };
          }
          return serialized;
        };

        var data = {
          name: this.formData.name,
          description: this.formData.description,
          active: this.formData.active,
          normative: this.formData.normative,
          parameters: this.formData.parameters.map(serializeParameter),
        };
        this.isProcessing = true;

        try {
          if (this.dialogueId == null) {
            await DialogueService.createDialogue(data);
          } else {
            await DialogueService.updateDialogue(this.dialogueId, data);
          }
          this.closeDialog();
          this.$emit('refresh', true);
        } catch (error) {
          this.error = error;
        }
        this.showWaitModal = false;
        this.isProcessing = false;
      }
    },
    setNextId: function () {
      if (this.formData.parameters.length > 0) {
        this.nextid = this.findMaxId(this.formData.parameters) + 1;
      }
    },
    findMaxId: function (items) {
      let maxId = 0;
      items.forEach((item) => {
        maxId = Math.max(maxId, item.id);
        if (item.children?.length > 0) {
          maxId = Math.max(maxId, this.findMaxId(item.children));
        }
      });
      return maxId;
    },

    validateParameters: function (obj, parent = null) {
      let result = true;

      if (obj.type === DialogueParameterTypes.QUESTION || obj.parent?.questionType === QuestionTypes.CUSTOM) {
        let fr = obj.languages.find((x) => x.language === DialogueLanguages.FRENCH);
        if (!fr || fr.value === '' || fr.value === undefined || fr.value === null) {
          this.activeItems = [obj];
          this.selectedLanguage = DialogueLanguages.FRENCH;
          result = false;
        }
      }
      for (let i = 0; i < obj.children?.length && result; i++) {
        if (obj.children?.length > 0) {
          let childResult = this.validateParameters(obj.children[i], parent ? [...parent, obj] : [obj]);
          result = result && childResult;
        }
      }
      return result;
    },

    closeDialog: function () {
      this.$emit('update:show', false);
    },
    removeItemFromParameters: function (array, idToRemove) {
      for (let index = 0; index < array.length; index++) {
        let element = array[index];
        if (element.id === idToRemove) {
          array.splice(index, 1);
          return true;
        }

        if (element.children) {
          if (this.removeItemFromParameters(element.children, idToRemove)) {
            return true;
          }
        }
      }
      return false;
    },
    deleteQuestion() {
      this.removeItemFromParameters(this.formData.parameters, this.activeItems[0].id);
      this.activeItems = [];
    },
    addQuestion(response) {
      let question = {
        id: this.nextid,
        type: DialogueParameterTypes.QUESTION,
        questionType: QuestionTypes.YESNO,
        value: '',
        languages: [
          {
            value: '',
            language: DialogueLanguages.FRENCH,
          },
          {
            value: '',
            language: DialogueLanguages.ENGLISH,
          },
        ],
        children: [
          {
            id: this.nextid + 1,
            type: DialogueParameterTypes.RESPONSE,
            icon: null,
            value: YesNoValues.YES,
            detailResponse: '',
            alertLevel: 'normal',
            endDialogueExecution: false,
            children: null,
            parent: undefined,
          },
          {
            id: this.nextid + 2,
            type: DialogueParameterTypes.RESPONSE,
            icon: null,
            value: YesNoValues.NO,
            alertLevel: 'normal',
            endDialogueExecution: false,
            children: null,
            parent: undefined,
          },
        ],
      };
      // Add a pointer to the parent for each child, to have access to parent information through the child.
      // This property is only useful for FE, and should not be serialized when sending to the BE.
      question.children.forEach((child) => (child.parent = question));
      this.nextid += 3;
      if (response) {
        response.children = response.children || [];
        response.children.push(question);
      } else {
        this.formData.parameters.push(question);
      }
      this.activeItems = [question];
    },
    addParentsToResponseChildren(element) {
      // Method to add a pointe to the parent of each child of type 'response', to have access to parent information through the child.
      // This property is only useful for FE, and should not be serialized when sending to the BE.
      if (element.children ?? element.children?.length > 0) {
        element.children.forEach((child) => {
          if (child.type === 'response') {
            child.parent = element;
          }
          this.addParentsToResponseChildren(child);
        });
      }
    },
    addResponse(question) {
      if (
        !(question && question.questionType === QuestionTypes.CUSTOM && question.children.length < MaxCustomResponses)
      ) {
        return;
      }
      question.children.push({
        id: this.nextid,
        type: DialogueParameterTypes.RESPONSE,
        icon: null,
        value: null,
        detailResponse: '',
        alertLevel: 'normal',
        endDialogueExecution: false,
        children: null,
        parent: question,
        languages: [
          {
            value: '',
            language: DialogueLanguages.FRENCH,
          },
          {
            value: '',
            language: DialogueLanguages.ENGLISH,
          },
        ],
      });
      this.nextid += 1;
    },
    deleteResponse() {
      let numberOfSiblings = this.activeItems[0].parent.children.length;
      if (numberOfSiblings <= MinResponses) {
        return;
      }
      this.removeItemFromParameters(this.formData.parameters, this.activeItems[0].id);
      this.activeItems = [this.activeItems[0].parent];
    },
    resetResponses(questionType) {
      if (!this.activeItem || this.activeItem.type !== DialogueParameterTypes.QUESTION) {
        return;
      }

      this.activeItem.questionType = questionType;
      let children = null;
      switch (questionType) {
        case QuestionTypes.YESNO:
          children = [
            {
              id: this.nextid + 1,
              type: DialogueParameterTypes.RESPONSE,
              icon: null,
              value: YesNoValues.YES,
              detailResponse: '',
              alertLevel: 'normal',
              endDialogueExecution: false,
              children: null,
              parent: this.activeItem,
            },
            {
              id: this.nextid + 2,
              type: DialogueParameterTypes.RESPONSE,
              icon: null,
              value: YesNoValues.NO,
              alertLevel: 'normal',
              endDialogueExecution: false,
              children: null,
              parent: this.activeItem,
            },
          ];
          this.activeItem.children = children;
          break;
        case QuestionTypes.RANGE:
          children = [];
          for (let i = 1; i <= 10; i++) {
            this.nextid += 1;
            children.push({
              id: this.nextid,
              type: DialogueParameterTypes.RESPONSE,
              icon: null,
              value: i.toString(),
              detailResponse: '',
              alertLevel: 'normal',
              endDialogueExecution: false,
              children: null,
              parent: this.activeItem,
            });
          }
          this.nextid += 1;
          this.activeItem.children = children;
          break;
        case QuestionTypes.CUSTOM:
          children = [];
          for (let i = 1; i <= 3; i++) {
            this.nextid += 1;
            children.push({
              id: this.nextid,
              type: DialogueParameterTypes.RESPONSE,
              icon: null,
              value: null,
              detailResponse: '',
              alertLevel: 'normal',
              endDialogueExecution: false,
              children: null,
              parent: this.activeItem,
              languages: [
                {
                  value: '',
                  language: DialogueLanguages.FRENCH,
                },
                {
                  value: '',
                  language: DialogueLanguages.ENGLISH,
                },
              ],
            });
          }
          this.nextid += 1;
          this.activeItem.children = children;
          break;
        case QuestionTypes.OPEN:
          children = [];
          this.nextid += 1;
          children.push({
            id: this.nextid,
            type: DialogueParameterTypes.RESPONSE,
            icon: null,
            value: null,
            detailResponse: '',
            alertLevel: 'normal',
            endDialogueExecution: false,
            children: null,
            parent: this.activeItem,
          });

          this.nextid += 1;
          this.activeItem.children = children;
          break;

        default:
          console.error('invalidQuestionType', questionType);
          break;
      }
    },
    hasModifications: function () {
      if (this.show) {
        return JSON.stringify(this.formData) !== JSON.stringify(this.originalFormData);
      }
    },
    toggleExpand() {
      if (this.openList.length > 0) {
        this.openList = [];
      } else {
        let flatten = (items) => items.flatMap((x) => (x.children?.length ? [x, ...flatten(x.children)] : x));
        this.openList = flatten(this.formData.parameters).filter((x) => x.children?.length);
      }
    },
  },
};
</script>

<style scoped>
.tabs.v-tabs-items {
  overflow: visible;
}
.translation-legend-icon {
  background: #fedcb2;
  border: #dc5f26 solid 1px;
  height: 12px;
  width: 12px;
}
.full-width {
  width: 100%;
}
</style>
