import { Auth } from '@aws-amplify/auth';
import applicationService from '@/services/applicationService';
import recaptcha from './recaptcha';

export class AuthConfig {
  constructor() {
    this.configured = false;
  }

  /**
   * Configure the Amplify Auth module with the config supplied by the Backend
   */
  async configure() {
    if (this.configured) {
      return;
    }

    let data = await applicationService.getAuthConfig();
    let config = {
      region: data.region,
      userPoolId: data.userPoolId,
      userPoolWebClientId: data.userPoolWebClientId,
      mandatorySignIn: false,
      signUpVerificationMethod: 'code',
    };

    Auth.configure(config);

    recaptcha.configure(data.recaptchaKey);

    this.configured = true;
  }
}

export default new AuthConfig();
