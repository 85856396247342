<template>
  <span>
    <ErrorModal :error="error" @close-error-modal="error = null" />
    <ModificationWarnModal :has-modifications="hasModifications" />

    <v-dialog v-if="show" ref="dialogRef" value="show" scrollable persistent max-width="1200px">
      <v-card>
        <ModalTitle :title="title" />

        <WaitModal :show="showWaitModal" />

        <UserError :error-array="requiredErrorMessages" @clearMsgs="clearErrorMessages" />
        <v-tabs ref="patientModalTabs" v-model="selectedPatientTab" class="px-6">
          <v-tab> {{ $t('patient') }} </v-tab>
          <v-tab> {{ $t('clinicalData') }} </v-tab>
          <v-tab> {{ $t('caregiver') }} </v-tab>
        </v-tabs>
        <v-card-text>
          <v-tabs-items v-model="selectedPatientTab" class="patient-tabs">
            <v-tab-item eager class="pt-4">
              <v-form ref="patientModalForm" lazy-validation>
                <PatientInfoTab
                  :patient-id="patientId"
                  :form-data-props="formData"
                  :genders="genders"
                  :languages="languages"
                  :countries="countries"
                  :provinces="provinces"
                  :province-label="provinceLabel"
                  :provinces-scope-changed="provincesScopeChanged"
                  :organizations="organizations"
                  :organizations-scope-changed="organizationsScopeChanged"
                  :facilities="facilities"
                  :facilities-scope-changed="facilitiesScopeChanged"
                  :patient-types="patientTypes"
                  :has-roombeds="hasRoombeds"
                  :roombeds="roombeds"
                  :roombeds-scope-changed="roombedsScopeChanged"
                ></PatientInfoTab>
              </v-form>
            </v-tab-item>
            <v-tab-item eager class="pt-4">
              <v-form ref="clinicalDataForm" lazy-validation>
                <ClinicalDataTab :form-data-props="formData"></ClinicalDataTab>
              </v-form>
            </v-tab-item>
            <v-tab-item eager class="pt-4">
              <v-form ref="caregiverModalForm" lazy-validation>
                <CaregiverInfoTab :form-data-props="formData" :caregiver-roles="caregiverRoles"></CaregiverInfoTab>
              </v-form>
              <v-row>
                <RequiredFieldsLegend v-if="!formData.hasDci" />
              </v-row>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            v-if="selectedPatientTab === PatientTabNumbers.CAREGIVER && !formData.hasDci"
            ref="addCaregiver"
            color="primary"
            :disabled="cannotAddCaregiver()"
            @click="addCaregiver"
            >{{ $t('addCaregiver') }}</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn ref="cancel" text :disabled="isProcessing" @click="closeDialog()">
            {{ $t('cancel') }}
          </v-btn>
          <SaveButton
            :is-loading="isLoading"
            :is-processing="isProcessing"
            :show-wait-modal="showWaitModal"
            :handle-click="createOrUpdateAction"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import translation from '@/translationMixin';
import accessibility from '@/accessibilityMixin';
import patientService from '@/services/patientService';
import validationRulesMixin from '@/validationRulesMixin';
import PatientInfoTab from './PatientTabs/PatientInfoTab';
import CaregiverInfoTab from './PatientTabs/CaregiverInfoTab';
import ClinicalDataTab from './PatientTabs/ClinicalDataTab';
import SaveButton from '@/components/SaveButton.vue';
import { CaregiverFormData } from './CaregiverFormData';
import { PatientFormData } from './PatientFormData';

const PatientTabNumbers = {
  PATIENT: 0,
  CLINICAL_DATA: 1,
  CAREGIVER: 2,
};

export default {
  name: 'PatientModal',
  components: { PatientInfoTab, CaregiverInfoTab, ClinicalDataTab, SaveButton },
  mixins: [translation, accessibility, validationRulesMixin],
  props: {
    patientId: {
      type: Number,
      required: false,
      default: null,
    },
    show: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      patientTypeFacility: { homecare: ['CLSC'], residency: ['CHSLD', 'RES'], hospitalized: ['CLSC'] },
      error: null,
      title: '',
      PatientTabNumbers: PatientTabNumbers,
      selectedPatientTab: PatientTabNumbers.PATIENT,
      showWaitModal: false,
      isProcessing: false,
      isLoading: false,
      countries: [],
      genders: [],
      languages: [],
      patientTypes: [],
      caregiverRoles: [],
      provinces: [],
      allProvinces: [],
      active: true,
      organizations: [],
      allOrganizations: [],
      facilities: [],
      facilitiesTypeFiltered: [],
      allFacilities: [],
      roombeds: [],
      allRoombeds: [],
      formData: new PatientFormData(),
      originalFormData: {},
    };
  },
  computed: {
    patientTypeCodeSelected() {
      return this.patientTypes.find(({ id }) => id === this.formData.patientType)?.code;
    },
    facilityTypeFilter() {
      return this.patientTypeFacility[this.patientTypeCodeSelected];
    },
    hasRoombeds() {
      return ['CHSLD', 'RES'].includes(this.facilities?.find(({ id }) => id === this.formData.facilityId)?.type?.code);
    },
    provinceLabel() {
      const selectedCountry = this.countries.find((country) => country.id === this.formData.countryId);
      if (selectedCountry?.code === 'FR') {
        return this.$t('department');
      } else {
        return this.$t('province');
      }
    },
  },

  watch: {
    async show() {
      if (this.show) {
        this.init();
        if (this.patientId != null) {
          await this.editClicked();
        } else {
          await this.init();
        }
        await this.getAllSelects();
      }
    },
  },
  methods: {
    async init() {
      this.title = this.$t('createPatient');
      this.selectedPatientTab = this.PatientTabNumbers.PATIENT;
      this.provinces = [];
      this.formData = new PatientFormData();
      this.originalFormData = JSON.parse(JSON.stringify(this.formData));
    },

    async editClicked() {
      this.title = this.$t('editPatient');
      this.showWaitModal = false;
      this.isLoading = true;

      try {
        var patient = await patientService.getPatientById(this.patientId);
        this.formData.setData(patient);

        this.originalFormData = JSON.parse(JSON.stringify(this.formData));
      } catch (error) {
        this.error = error;
      }

      this.isLoading = false;
    },
    async createOrUpdateAction() {
      if (this.isProcessing) return;

      let areAllFormsValid = await this.triggerAllFormValidations();
      if (!areAllFormsValid) return;

      this.showWaitModal = true;
      this.isProcessing = true;

      var data = {
        patientId: this.patientId,
        mrn: this.formData.mrn,
        firstName: this.formData.firstName,
        lastName: this.formData.lastName,
        birthDate: this.formData.birthDate,
        nasm: this.formData.nasm,
        phone: this.formData.phone,
        email: this.formData.email ? this.formData.email.toLowerCase() : null,
        address: this.formData.address,
        city: this.formData.city,
        postalCode: this.formData.postalCode,
        countryId: this.formData.countryId,
        genderId: this.formData.gender,
        languageId: this.formData.language,
        patientTypeId: this.formData.patientType,
        provinceId: this.formData.provinceId,
        active: this.formData.active,
        organizationId: this.formData.organizationId,
        facilityId: this.formData.facilityId,
        roombedId: this.formData.roombedId,
        startDate: this.formData.startDate,
        endDate: this.formData.endDate,
        baselines: this.formData.baselines,
        healthInfo: this.formData.healthInfo,
        caregiverInfo: this.formData.caregiverInfo.map((caregiver) => {
          return {
            ...caregiver,
            caregiverEmail: caregiver.caregiverEmail ? caregiver.caregiverEmail.toLowerCase() : null,
          };
        }),
      };

      try {
        if (this.patientId === null) {
          await patientService.createPatient(data);
        } else {
          await patientService.updatePatient(this.patientId, data);
        }
        this.closeDialog(true);
      } catch (error) {
        this.error = error;
      }
      this.showWaitModal = false;
      this.isProcessing = false;
    },

    addCaregiver() {
      this.formData.caregiverInfo.push(new CaregiverFormData());
    },

    cannotAddCaregiver() {
      const arrayLength = this.formData.caregiverInfo?.length;
      let indexCheck = 0;
      if (arrayLength > 1) {
        indexCheck = this.formData.caregiverInfo.length - 1;
      }
      if (
        this.formData.caregiverInfo[indexCheck]?.caregiverFirstName &&
        this.formData.caregiverInfo[indexCheck]?.caregiverLastName &&
        this.formData.caregiverInfo[indexCheck]?.caregiverPhone &&
        this.formData.caregiverInfo[indexCheck]?.caregiverRelation &&
        this.formData.caregiverInfo[indexCheck]?.caregiverRole
      ) {
        return false;
      }
      return true;
    },

    closeDialog: function (refresh) {
      this.$emit('close', refresh);
    },

    async getAllSelects() {
      this.showWaitModal = false;
      this.isLoading = true;

      try {
        let data = await patientService.getPatientParameters();

        this.countries = data.countries;
        this.genders = data.genders;
        this.languages = data.languages;
        this.patientTypes = data.patientTypes;
        this.caregiverRoles = data.caregiverRoles;

        this.allOrganizations = data.organizations;
        this.allFacilities = data.facilities;

        this.allRoombeds = data.roombeds.map(this.convertToValueLabelRoombed);
        this.organizationsScopeChanged(false);

        this.allProvinces = data.provinces.map(this.convertProvinceWithConventionalName);
        this.provincesScopeChanged(false);
      } catch (error) {
        this.error = error;
      }

      this.isLoading = false;
    },

    convertToValueLabelRoombed: function (object) {
      return {
        value: object.id,
        label: this.getRoombedText(object),
        facilityId: object.facility ? object.facility.id : null,
      };
    },

    convertProvinceWithConventionalName: function (province) {
      if (province?.country?.code === 'FR') {
        return {
          id: province.id,
          code: province.code,
          name: province.code + ' - ' + province.name,
          countryId: province?.country?.id,
        };
      } else {
        return {
          id: province.id,
          code: province.code,
          name: province.name,
          countryId: province?.country?.id,
        };
      }
    },

    organizationsScopeChanged: function (resetValue) {
      this.facilitiesTypeFiltered = this.allFacilities.filter((facility) => {
        return this.facilityTypeFilter?.includes(facility.type.code);
      });
      this.organizations = this.allOrganizations.filter((org) => {
        return this.facilitiesTypeFiltered.find(({ organization }) => organization.id === org.id);
      });

      resetValue ? (this.formData.organizationId = null) : null;
      this.facilitiesScopeChanged(resetValue);
    },

    facilitiesScopeChanged: function (resetValue) {
      this.facilities = this.facilitiesTypeFiltered.filter((facility) => {
        return (facility.organization ? facility.organization.id : null) === this.formData.organizationId;
      });

      resetValue ? (this.formData.facilityId = null) : null;
      this.roombedsScopeChanged(resetValue);
    },

    roombedsScopeChanged: function (resetValue) {
      this.roombeds = this.allRoombeds.filter((roombed) => {
        return roombed.facilityId === this.formData.facilityId;
      });
      resetValue ? ((this.formData.roombedId = null), (this.startDate = null)) : null;
    },

    provincesScopeChanged(resetValue) {
      this.provinces = this.allProvinces
        .filter((province) => {
          return province.countryId === this.formData.countryId;
        })
        .sort(this.provinceSortFunc);

      resetValue ? (this.formData.provinceId = null) : null;
    },

    hasModifications: function () {
      if (this.show) {
        return JSON.stringify(this.formData) !== JSON.stringify(this.originalFormData);
      }
    },

    provinceSortFunc(a, b) {
      let expression = /(?<nbr>[0-9]*)(?<str>.*)/;
      let matchA = a.name.match(expression);
      let matchB = b.name.match(expression);

      // If we have a province with the code 2A and another one with the code 20, we want
      // the 2A to appear before the 20 in the list

      if (matchA.groups.nbr && matchB.groups.nbr) {
        if (matchA.groups.nbr < matchB.groups.nbr) {
          return -1;
        } else if (matchA.groups.nbr > matchB.groups.nbr) {
          return 1;
        }
      }

      if (matchA.groups.nbr || matchB.groups.nbr) {
        return matchA.groups.nbr ? 1 : -1;
      }

      if (matchA.groups.str || matchB.groups.str) {
        if (matchA.groups.str && matchB.groups.str) {
          return matchA.groups.str.localeCompare(matchB.groups.str);
        }
        return matchA.groups.str ? 1 : -1;
      }

      return a.name.localeCompare(b.name);
    },
    getRoombedText(roombed) {
      return roombed.bed ? `${roombed.room} - ${roombed.bed}` : roombed.room;
    },
    async triggerAllFormValidations() {
      // Trigger validation on all forms, to highlight all invalid fields
      let isPatientModalFormValid = this.$refs.patientModalForm.validate();
      let isClinicalDataFormValid = this.$refs.clinicalDataForm.validate();
      let isCaregiverModalFormValid = this.$refs.caregiverModalForm.validate();

      if (!isPatientModalFormValid) {
        await this.scrollToInvalidField(this.$refs.patientModalForm, this.PatientTabNumbers.PATIENT);
      } else if (!isClinicalDataFormValid) {
        await this.scrollToInvalidField(this.$refs.clinicalDataForm, this.PatientTabNumbers.CLINICAL_DATA);
      } else if (!isCaregiverModalFormValid) {
        await this.scrollToInvalidField(this.$refs.caregiverModalForm, this.PatientTabNumbers.CAREGIVER);
      }
      return isPatientModalFormValid && isClinicalDataFormValid && isCaregiverModalFormValid;
    },
    async scrollToInvalidField(formWithInvlidFieldRef, tabWithInvalidField) {
      if (this.selectedPatientTab != tabWithInvalidField) {
        this.selectedPatientTab = tabWithInvalidField;
        await this.waitFor(300);
      } else {
        this.selectedPatientTab = tabWithInvalidField;
      }

      let firstInvalidField = formWithInvlidFieldRef.inputs.find((i) => i.valid === false);
      firstInvalidField?.$el.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    },
    async waitFor(delay) {
      return new Promise((resolve) => setTimeout(resolve, delay));
    },
  },
};
</script>

<style scoped>
.patient-tabs.v-tabs-items {
  overflow: visible;
}
</style>
