// eslint-disable-next-line no-unused-vars
import { CognitoUser } from 'amazon-cognito-identity-js';
import { Auth } from '@aws-amplify/auth';
import listener, { ListenerEvents } from './authListener';
import recaptcha from './recaptcha';
import { v4 as uuidv4 } from 'uuid';

/**
 * @typedef {string} AuthResultEnum
 **/

/**
 * Enum for AuthResult values.
 * @readonly
 * @enum {AuthResultEnum}
 */
export const AuthResult = {
  SignedIn: 'SignedIn',
  Successs: 'Success',
  NewPasswordRequired: 'NewPasswordRequired',
  MFASetup: 'MFASetup',
  MFARequired: 'MFARequired',
  UnhandledChallenge: 'UnhandledChallenge',
  InvalidCredentials: 'InvalidCredentials',
  InvalidCode: 'InvalidCode',
  ExpiredCode: 'ExpiredCode',
  InvalidPasswordStructure: 'InvalidPasswordStructure',
  LimitExceeded: 'LimitExceeded',
  Error: 'Error',
  ReCaptchaValidationFailed: 'ReCaptchaValidationFailed',
  TooManyAttempts: 'TooManyAttempts',
};

export class AmplifyAuth {
  constructor() {
    /**
     * @type {CognitoUser}
     */
    this.user = null;

    this._isAuthenticated = false;
  }

  /**
   * Returns `true` if a user is currently authenticated
   * @returns {boolean}
   */
  isAuthenticated() {
    return this._isAuthenticated;
  }

  /**
   * Try to authenticate a user using data in local storage. Returns `true` if signIn was sucessfull
   * @returns {Promise<boolean>}
   */
  async silentSignIn() {
    try {
      let user = await Auth.currentAuthenticatedUser();
      this.user = user;
      this._isAuthenticated = true;
      listener.raise(ListenerEvents.signIn);
      return true;
    } catch (error) {
      return false;
    }
  }

  /**
   * Sign in with specified username and password
   * @param {string} username
   * @param {string} password
   * @returns {Promise<AuthResult>}
   */
  async signIn(username, password) {
    try {
      const deviceKey = this.getDeviceKey();
      const token = await recaptcha.getToken('LOGIN');
      const userAgent = navigator.userAgent;

      const metadata = {
        token: token,
        userAgent: userAgent,
        deviceKey: deviceKey,
      };
      const user = await Auth.signIn(username, password, metadata);

      this.user = user;
      if (user.challengeName) {
        switch (user.challengeName) {
          case 'MFA_SETUP':
            return AuthResult.MFASetup;
          case 'SOFTWARE_TOKEN_MFA':
            return AuthResult.MFARequired;
          case 'NEW_PASSWORD_REQUIRED':
            return AuthResult.NewPasswordRequired;
          default:
            return AuthResult.UnhandledChallenge;
        }
      }
      this._isAuthenticated = true;
      return AuthResult.SignedIn;
    } catch (error) {
      this.user = null;
      switch (error?.code) {
        case 'NotAuthorizedException':
          return AuthResult.InvalidCredentials;
        case 'UserLambdaValidationException':
          if (error?.message?.includes('reCaptcha validation failed')) {
            return AuthResult.ReCaptchaValidationFailed;
          } else if (error?.message?.includes('User disabled : Too many login attempts')) {
            return AuthResult.TooManyAttempts;
          }
          console.error('signIn', error);
          return AuthResult.Error;
        default:
          console.error('signIn', error);
          return AuthResult.Error;
      }
    }
  }

  getDeviceKey() {
    let deviceKey = localStorage.getItem('deviceKey');

    if (!deviceKey) {
      deviceKey = uuidv4();
      localStorage.setItem('deviceKey', deviceKey);
    }

    return deviceKey;
  }

  /**
   * Start an authentication flow with the specified provider
   * @param {string} provider
   */
  async federatedSignIn(provider) {
    await Auth.federatedSignIn({ provider: provider });
  }

  /**
   * Complete the sign in flow with a MFA code
   * @param {string} code
   * @returns {Promise<AuthResult>}
   */
  async confirmSignIn(code) {
    try {
      await Auth.confirmSignIn(this.user, code, 'SOFTWARE_TOKEN_MFA');
      this._isAuthenticated = true;
      return AuthResult.SignedIn;
    } catch (error) {
      switch (error?.code) {
        case 'CodeMismatchException':
          return AuthResult.InvalidCode;
        case 'ExpiredCodeException':
          return AuthResult.ExpiredCode;
        default:
          console.error('configmSignIn', error);
          return AuthResult.Error;
      }
    }
  }

  /**
   * Complete the sign in flow with the confirmation of a new password
   * @param {string} password
   * @returns {Promise<AuthResult>}
   */
  async completeNewPassword(newPassword) {
    try {
      const user = await Auth.completeNewPassword(this.user, newPassword);
      this.user = user;
      if (user.challengeName) {
        switch (user.challengeName) {
          case 'MFA_SETUP':
            return AuthResult.MFASetup;
          case 'SOFTWARE_TOKEN_MFA':
            return AuthResult.MFARequired;
          default:
            return AuthResult.UnhandledChallenge;
        }
      }
      this._isAuthenticated = true;
      return AuthResult.SignedIn;
    } catch (error) {
      switch (error?.code) {
        case 'InvalidPasswordException':
          return AuthResult.InvalidPasswordStructure;
        case 'LimitExceededException':
          return AuthResult.LimitExceeded;
        default:
          console.error('completeNewPassword', error);
          return AuthResult.Error;
      }
    }
  }

  /**
   * Changes the password of the authenticated user
   * @param {string} oldPassword
   * @param {string} newPassword
   * @returns {Promise<AuthResult>} Returns `AuthResult.Successs` if successful
   */
  async changePassword(oldPassword, newPassword) {
    try {
      await Auth.changePassword(this.user, oldPassword, newPassword);
      return AuthResult.Successs;
    } catch (error) {
      switch (error?.code) {
        case 'InvalidPasswordException':
          return AuthResult.InvalidPasswordStructure;
        default:
          console.error('changePassword', error);
          return AuthResult.Error;
      }
    }
  }

  /**
   * Signs out the authenticated user
   * @param {string} password
   * @returns {Promise<boolean>}
   */
  async signOut() {
    try {
      this.user = null;
      this._isAuthenticated = false;
      await Auth.signOut();
      return true;
    } catch (error) {
      console.error('signOut', error);
      return false;
    }
  }

  /**
   * Initiates a forgotPassword request
   * @param {string} username
   * @returns {Promise<boolean>}
   */
  async forgotPassword(username) {
    try {
      await Auth.forgotPassword(username);
      return true;
    } catch (error) {
      console.error('forgotPasswordError', error);
      return false;
    }
  }

  /**
   * Sets a new password using confirmation code
   * @param {string} username
   * @param {string} code
   * @param {string} newPassword
   * @returns {Promise<AuthResult>} Returns `AuthResult.Successs` if successful
   */
  async forgotPasswordSubmit(username, code, newPassword) {
    try {
      await Auth.forgotPasswordSubmit(username, code, newPassword);
      return AuthResult.Successs;
    } catch (error) {
      switch (error?.code) {
        case 'CodeMismatchException':
        case 'ExpiredCodeException':
          return AuthResult.InvalidCode;
        case 'InvalidPasswordException':
          return AuthResult.InvalidPasswordStructure;
        case 'LimitExceededException':
          return AuthResult.LimitExceeded;
        default:
          console.error('submitForgotPasswordCode', error);
          return AuthResult.Error;
      }
    }
  }

  /**
   * Get a new TOTP (time-based one-time password) secret code
   * @returns {Promise<string>}
   */
  async setupTOTP() {
    let code = await Auth.setupTOTP(this.user);
    return `otpauth://totp/Virtuose%20Console:${this.user.username}?secret=${code}&issuer=Virtuose%20Console`;
  }

  /**
   * Verify/accept the TOPT configuration geretared using `setupTOPT`
   * @param {string} code Code generated with an Authenticator using the TOPT configuration generated with `setupTOTP`
   * @returns {Promise<boolean>}
   */
  async verifyTOTP(code) {
    try {
      await Auth.verifyTotpToken(this.user, code);
      return true;
    } catch (error) {
      console.error('verifyTOTP', error);
      return false;
    }
  }

  /**
   * Checks if a TOTP MFA is activated
   * @returns {Promise<boolean>}
   */
  async isMFAActivated() {
    try {
      let status = await Auth.getPreferredMFA(this.user, { bypassCache: true });
      return status === 'SOFTWARE_TOKEN_MFA';
    } catch (error) {
      console.error('verifyTOTP', error);
      return false;
    }
  }

  /**
   * Activates the TOTP MFA configured for the user
   * @returns {Promise<boolean>}
   */
  async activateMFA() {
    try {
      await Auth.setPreferredMFA(this.user, 'TOTP');
      return true;
    } catch (error) {
      console.error('setPreferredMFA', error);
      return false;
    }
  }

  /**
   * Deactivates the TOTP MFA configured for the user
   * @returns {Promise<boolean>}
   */
  async deactivateMFA() {
    try {
      await Auth.setPreferredMFA(this.user, 'NOMFA');
      return true;
    } catch (error) {
      console.error('setPreferredMFA', error);
      return false;
    }
  }

  /**
   * Get the JWT AccessToken for the authenticated user
   * @returns {Promise<string>}
   */
  async getAccessToken() {
    try {
      let session = await Auth.currentSession();
      return session.getAccessToken().getJwtToken();
    } catch (error) {
      // if there's an exception, we will generate a signout in order to re-authenticate the user
      this._isAuthenticated = false;
      this.user = null;
      listener.raise(ListenerEvents.signOut);
      throw error;
    }
  }

  /**
   * Get the JWT IdToken for the authenticated user
   * @returns {Promise<string>}
   */
  async getIdToken() {
    let session = await Auth.currentSession();
    return session.getIdToken().getJwtToken();
  }

  /**
   * Get current authenticated user attributes
   * @returns {Promise<any>}
   */
  async getUserAttributes() {
    let user = await Auth.currentAuthenticatedUser();
    return {
      username: user.username,
      ...user.attributes,
    };
  }

  /**
   * Update an authenticated users' attributes
   * @param {*} attributes
   * @returns {Promise<boolean>}
   */
  async updateUserAttributes(attributes) {
    try {
      await Auth.updateUserAttributes(this.user, attributes);
      return true;
    } catch (error) {
      console.error('updateUserAttributes', error);
      return false;
    }
  }

  /**
   * Initiate an attribute confirmation request for the current user
   * @param {string} attribute
   * @returns {Promise<true>}
   */
  async verifyUserAttribute(attribute) {
    try {
      await Auth.verifyCurrentUserAttribute(attribute);
      return true;
    } catch (error) {
      console.error('verifyUserAttribute', error);
      return false;
    }
  }

  /**
   * Confirm current user's attribute using a confirmation code
   * @param {string} attribute
   * @param {string} code
   * @returns {Promise<true>}
   */
  async verifyUserAttributeSubmit(attribute, code) {
    try {
      await Auth.verifyCurrentUserAttributeSubmit(attribute, code);
      return true;
    } catch (error) {
      console.error('verifyUserAttributeSubmit', error);
      return false;
    }
  }
}

export default new AmplifyAuth();
