<template>
  <div>
    <ErrorModal :error="error" @close-error-modal="error = null" />
    <v-row>
      <v-col>
        <v-btn v-if="isPatientHealthWorker" ref="addNote" color="primary" exact @click="showAddNoteAction(null)">
          <span>{{ $t('add') }}</span>
        </v-btn>
      </v-col>
    </v-row>

    <PatientMonitoringFilter
      :initial-values="filters"
      :use-monitoring-status="false"
      :show-required-icon="false"
      class="mt-4 mb-n8 break-before"
      @filter="onMonitoringFilter"
    />

    <DataGrid
      :items="notes"
      :columns="columns"
      grid-name="notes_grid"
      :show-select="false"
      :items-per-page="25"
      :data-loading="showWaitModal"
    >
      <template #item.actions="{ item }">
        <div class="text-end">
          <v-btn :ref="`actions_${item.id}`" :text="true" @click="consultPatientNote(item.id)">{{
            $t('patientNote.consult')
          }}</v-btn>
        </div>
      </template>

      <template #item.noteType="{ item }">
        <div>
          <span>{{ item.noteTypeText }}</span>
        </div>
      </template>

      <template #item.noteContent="{ item }">
        <v-tooltip top max-width="1000px">
          <template #activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              <div class="dynamic-max-width" :style="{ maxWidth: cellMaxWidth }">
                {{ item.noteContent }}
              </div>
            </span>
          </template>
          <span>{{ item.noteContent }}</span>
        </v-tooltip>
      </template>

      <template #item.createdBy="{ item }">
        <div>
          <span>{{ item.createdBy.firstName }} {{ item.createdBy.lastName }}</span>
        </div>
      </template>
    </DataGrid>

    <NotesModal
      v-if="showModal"
      :show.sync="showModal"
      :patient-id="patientId"
      :note-id="selectedNoteId"
      @refresh="init()"
    />
  </div>
</template>

<script>
import translation from '@/translationMixin';
import virtuoseMixin from '@/virtuoseMixin';
import permissionMixin from '@/auth/permissionMixin';
import PatientService from '@/services/patientService';
import NotesModal from './NotesModal.vue';
import DataGrid from '@/components/DataGrid.vue';
import PatientMonitoringFilter from '@/components/PatientMonitoring/PatientMonitoringFilter.vue';
import { TimeFilterType } from '@/components/PatientMonitoring/constants';
import { format } from 'date-fns';

export default {
  name: 'NotesGrid',
  components: { NotesModal, DataGrid, PatientMonitoringFilter },
  mixins: [translation, virtuoseMixin, permissionMixin],
  props: {
    patientId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      error: null,
      showModal: false,
      showWaitModal: false,
      notes: [],
      isPatientHealthWorker: false,
      selectedNoteId: null,
      noteTypesConst: {
        proNote: 'professional_note',
        intervention: 'intervention',
      },
      filters: {
        timeFilterType: TimeFilterType.RANGE,
        timeFilterValue: null,
        dateFrom: format(new Date('2024-01-02'), 'yyyy-MM-dd 00:00:00'),
        dateTo: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
      },
    };
  },
  computed: {
    columns() {
      let columns = [
        {
          text: 'patientNote.noteOrInterventionDate',
          value: 'noteOrInterventionDate',
          filterable: true,
          visible: true,
        },
        {
          text: 'patientNote.noteType',
          value: 'noteTypeText',
          filterable: true,
          visible: true,
        },
        {
          text: 'patientNote.note',
          value: 'noteContent',
          filterable: true,
          visible: true,
        },
        {
          text: 'patientNote.time',
          value: 'interventionTimeInMinutes',
          filterable: true,
          visible: true,
        },
        {
          text: 'patientNote.createdBy',
          value: 'createdByFullName',
          filterable: true,
          visible: true,
        },
        {
          text: 'patientNote.createdAt',
          value: 'createdAt',
          filterable: true,
          visible: true,
        },
      ];
      if (this.$can(this.permissions.actions.view, this.permissions.subjects.patient)) {
        columns.push({
          text: 'actions',
          value: 'actions',
          filterable: false,
          sortable: false,
        });
      }
      return columns;
    },
    cellMaxWidth() {
      return this.$vuetify.breakpoint.mdAndUp ? '200px' : '100px';
    },
  },
  watch: {
    patientId() {
      if (this.patientId) {
        this.getPatientNotes();
      }
    },
  },
  mounted() {
    if (this.patientId) {
      this.getPatientNotes();
    }
  },
  methods: {
    async init() {
      this.notes = [];
      this.selectedNoteId = null;
      this.getPatientNotes();
    },
    showAddNoteAction: function (noteId) {
      this.selectedNoteId = noteId;
      this.showModal = true;
    },

    async getPatientNotes() {
      this.showWaitModal = true;

      try {
        let [notesData, isPatientHealthWorker] = await Promise.all([
          PatientService.getPatientNotes(this.patientId, this.filters),
          PatientService.getUserIsPatientHealthworker(this.patientId),
        ]);

        this.notes = notesData.map((note) => ({
          ...note,
          createdByFullName: `${note.createdBy.firstName} ${note.createdBy.lastName}`,
          noteTypeText: this.getNoteTypeText(note.noteType),
          noteOrInterventionDate: note.interventionDate
            ? `${note.interventionDate} ${note.interventionStartTime}`
            : note.noteDate,
          interventionTimeInMinutes: note.interventionTime ? `${note.interventionTime} minutes` : '',
        }));
        this.isPatientHealthWorker = isPatientHealthWorker;
      } catch (error) {
        this.error = error;
      } finally {
        this.showWaitModal = false;
      }
    },
    consultPatientNote: function (noteId) {
      this.showAddNoteAction(noteId);
    },
    getNoteTypeText: function (noteValue) {
      if (noteValue === this.noteTypesConst.proNote) {
        return this.$t('patientNote.proNote');
      } else if (noteValue === this.noteTypesConst.intervention) {
        return this.$t('patientNote.intervention');
      }

      return '';
    },
    onMonitoringFilter: function (filters) {
      this.filters = filters;
      this.getPatientNotes();
    },
  },
};
</script>

<style scoped>
@media screen and (max-width: 600px) {
  ::v-deep form.v-form .row.print-end .col.col-12:nth-of-type(2) {
    padding-bottom: 0;
  }
  ::v-deep form.v-form .row.print-end .col.col-12:nth-of-type(3) {
    padding-top: 0;
    padding-bottom: 0;
  }
  ::v-deep form.v-form .row.print-end .col.col-12:nth-of-type(4) {
    padding-top: 0;
    padding-bottom: 40px;
  }
}
::v-deep table th[aria-label='actions'] {
  text-align: end !important;
}
::v-deep table th[aria-label='actions'] > span.datagrid-header {
  padding: 0 18px;
}
.dynamic-max-width {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media print {
  ::v-deep .v-data-table > .v-data-table__wrapper > table > thead > tr > th,
  ::v-deep .v-data-table > .v-data-table__wrapper > table > tbody td {
    padding-left: 15px !important;
  }
  ::v-deep .v-data-table {
    line-height: 1.5 !important;
  }
  .dynamic-max-width {
    max-width: none !important;
    white-space: normal;
  }
  .break-before {
    break-before: page;
  }
}
</style>
