import api from './baseApiService';

export class PatientMonitoringService {
  async getPatientBloodPressure(data) {
    return await api.get('patientmonitoringarterialpressure/', data);
  }

  async getPatientBodyTemperature(data) {
    return await api.get('patientmonitoringbodytemperature/', data);
  }

  async getPatientCardiacFrequency(data) {
    return await api.get('patientmonitoringcardiacfrequency/', data);
  }

  async getPatientSaturation(data) {
    return await api.get('patientmonitoringsaturation/', data);
  }

  async getPatientRespiratoryRate(data) {
    return await api.get('patientmonitoringrespiratoryrate/', data);
  }

  async getPatientWeight(data) {
    return await api.get('patientmonitoringweight/', data);
  }

  async getPatientFallDetection(data) {
    return await api.get('patientmonitoringfalldetection/', data);
  }

  async getPatientPrescriptiveLearning(data) {
    return await api.get('patientmonitoringprescriptivelearning/', data);
  }

  async getPatientIncontinence(data) {
    return await api.get('patientmonitoringincontinence/', data);
  }

  async getPatientPrescription(data) {
    return await api.get('patientmonitoringprescription/', data);
  }

  async getPatientCapillaryGlycemia(data) {
    return await api.get('patientmonitoringcapillaryglycemia/', data);
  }

  async getPatientDialoguesResults(data) {
    return await api.get('patientdialogueresults/', data);
  }

  async getPatientMonitoringSummary(data) {
    return await api.get('patientmonitoringsummary/', data);
  }

  async getPatientPhotosResults(data) {
    return await api.get('patientphotoresults/', data);
  }

  async getPatientPhotoResultsPictureUrl(patientPictureResultId) {
    return await api.get(`patientphotoresults/pictureurl/${patientPictureResultId}`);
  }

  async getPatientMonitoringChartParameters(data) {
    return await api.get('patientmonitoringchartparameters/', data);
  }

  async getPatientMonitoringEcg(data) {
    return await api.get('patientmonitoringecg/', data);
  }

  async getStatusLevels() {
    return await api.get('statuslevels');
  }

  async saveManualEntryVitalSigns(data) {
    return await api.post('patientmanualentry/', data);
  }
}

export default new PatientMonitoringService();
